/* ==========================================================================
   #LINK
   ========================================================================== */

.o-links-orange a,
.o-link-orange {
  @include link-active-hover-click {
    color: $color-orange;
  }
}
