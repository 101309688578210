/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-c-white {
  color: $color-white;
}

.u-c-navy-blue {
  color: $color-navy-blue;
}

.u-c-blue-darker {
  color: $color-blue-darker;
}

.u-c-blue-darkest {
  color: $color-blue-darkest;
}

.u-c-orange {
  color: $color-orange;
}

.u-c-text {
  color: $color-text;
}

.u-c-heading {
  color: $color-headings;
}

.u-c-gray-dark {
  color: $color-gray-dark;
}

.u-c-navy-blue-darker {
  color: $color-navy-blue-darker;
}
