/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: currentColor;
  text-decoration: none;
  transition: color $transition-fast-in ease;

  @include link-active-hover {
    color: $color-orange;
  }
}
