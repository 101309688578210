/* ==========================================================================
   #EXPAND
   ========================================================================== */

.o-expand {
  position: relative;
}

.o-expand__hidden,
.o-expand:before {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}

.o-expand[data-text]:before {
  content: attr(data-text);
}

.o-expand--600 .o-expand__hidden,
.o-expand--600:before {
  font-weight: 600;
}

.o-expand__target {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
}
