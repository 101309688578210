///* ========================================================================
//   #DROPDOWN STYLE
//   ======================================================================== */

ul.is-style-dropdown .dropdown-trigger {
  text-align: left;
  display: inline;
}

ul.is-style-dropdown > li > ul {
  list-style: none;
  margin-left: 0;
}

ul.is-style-dropdown > li:not(.is-active) > ul {
  display: none;
}
