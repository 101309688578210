///* ========================================================================
//   #GUTENBERG
//   ======================================================================== */

@mixin section {
  margin-top: 32px;
  margin-bottom: 32px;

  @include bp(medium) {
    & {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  @include bp(xlarge) {
    & {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
}
