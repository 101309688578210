/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.o-btn {
  @include button;
}

/* Style variants
   ========================================================================== */

.o-btn--primary {
  @include button-primary;
}

.o-btn--secondary {
  @include button-secondary;
}

.o-btn--outline {
  @include button-outline;
}

.o-btn--outline-white {
  @include button-outline-white;
}

/* Size variants
   ========================================================================== */

.o-btn--cta {
  min-width: 144px;
}
