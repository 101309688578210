/* ==========================================================================
   #IMAGES
   ========================================================================== */

img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

/* stylelint-disable */
html.js .js-lazy-img {
  transition: opacity 0.3s ease;
}

html.js .js-lazy-img:not(.js-lazy-loaded) {
  opacity: 0;
}
/* stylelint-enable */

