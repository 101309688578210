///* ========================================================================
//   #GUTENBERG EDITOR STYLES
//   ======================================================================== */

.has-h-1-font-size {
  @include h1;
}

.has-h-2-font-size {
  @include h2;
}

.has-h-3-font-size {
  @include h3;
}

.has-h-4-font-size {
  @include h4;
}

.has-h-5-font-size {
  @include h5;
}

.has-h-6-font-size {
  @include h6;
}

.wp-block-quote {
  max-width: 886px;
  border-left: 5px solid $color-orange;
  padding-left: 10px;
}

.wp-block-quote cite,
.wp-block-quote {
  font-style: normal;
}

.has-gray-color {
  color: $color-gray-dark;
}

.has-orange-color {
  color: $color-orange;
}

.has-white-color {
  color: $color-white;
}

.has-black-color {
  color: $color-black;
}

.has-gray-background-color {
  background-color: $color-gray-dark;
}

.has-orange-background-color {
  background-color: $color-orange;
}

.has-white-background-color {
  background-color: $color-white;
}

.has-black-background-color {
  background-color: $color-black;
}

.wp-block-quote .wp-block-quote__citation strong,
.wp-block-quote cite strong {
  color: $color-orange;
  @include smaller;
}

.wp-block-quote p {
  line-height: (24/14);
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: none;
  height: 0;
  border-bottom: 1px solid currentColor;
}

.wp-block-separator {
  border: none;
  border-bottom: 1px solid currentColor;
  height: 0;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: none;
}

.wp-block-separator:not(.has-text-color) {
  color: #c4cdd5;
}

.wp-block-quote a,
figcaption a {
  @include link-generic;
}

.is-style-nowrap {
  white-space: nowrap;
}

@include bp(medium) {
  .wp-block-quote {
    padding-left: 20px;
    margin-top: $block-bottom-margin * 1.5;
    margin-bottom: $block-bottom-margin * 1.5;
  }

  .wp-block-quote p {
    font-size: 25px;
    line-height: (32/25);
  }

  .wp-block-quote .wp-block-quote__citation,
  .wp-block-quote cite {
    font-size: 18px;
  }

  .wp-block-separator {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

@include bp(large) {
  .wp-block-separator {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@include bp(xlarge) {
  .wp-block-quote {
    margin-left: (53/781) * 100%;
    margin-top: $block-bottom-margin * 2.5;
    margin-bottom: $block-bottom-margin * 2.5;
    padding-left: 25px;
  }

  .c-section__columns > .wp-block-column:first-child .wp-block-quote {
    max-width: 100%;
    margin-left: 0;
  }
}

@include bp(full) {
  .wp-block-quote {
    width: 105%;
  }
}

@include bp(1600px) {
  .wp-block-quote {
    width: (861/781) * 100%;
  }
}
