/* ==========================================================================
   #FEATURED TILES
   ========================================================================== */

.c-featured-tiles {
  margin-top: 35px;
  padding-bottom: 35px;
  background: linear-gradient(to bottom, transparent 125px, $color-light-bg 125px, $color-light-bg) repeat-x top left;
}

.c-featured-tiles--no-bg.c-featured-tiles--no-bg {
  background-image: none;
}

.c-featured-tiles__items {
  margin-bottom: -20px;
}

.c-featured-tiles__item {
  border-top: 8px solid $color-orange;
  display: block;
}

.c-featured-tiles__items > * {
  margin-bottom: 20px;
  margin-left: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  background-color: $color-white;
}

.c-featured-tiles__media {
  position: relative;
  max-height: 130px;
  overflow: hidden;
}

.c-featured-tiles__media:before {
  content: '';
  display: block;
  padding-top: (106/262) * 100%;
}

.c-featured-tiles__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-featured-tiles__text {
  padding: 22px;
}

@include bp-down(large) {
  .c-featured-tiles__item:nth-child(n + 3) {
    display: none;
  }
}

@include bp(medium) {
  .c-featured-tiles {
    margin-top: 55px;
    padding-bottom: 55px;
    background: linear-gradient(to bottom, transparent 145px, $color-light-bg 145px, $color-light-bg) repeat-x top left;
  }

  .c-featured-tiles__item {
    border-top-width: 10px;
  }

  .c-featured-tiles__items > * {
    margin-left: 20px;
    width: calc(50% - 20px);
  }

  .c-featured-tiles__items {
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
  }

  .c-featured-tiles__title {
    margin-bottom: 30px;
  }
}

@include bp(large) {
  .c-featured-tiles__items {
    margin-left: -15px;
    margin-bottom: -15px;
  }

  .c-featured-tiles__items > * {
    margin-left: 15px;
    width: calc(100% / 3 - 15px);
    margin-bottom: 15px;
  }
}

@include bp(xlarge) {
  .c-featured-tiles__item {
    border-top-width: 12px;
  }

  .c-featured-tiles__title {
    margin-bottom: 45px;
  }

  .c-featured-tiles {
    margin-top: 90px;
    padding-bottom: 90px;
    background: linear-gradient(to bottom, transparent 170px, $color-light-bg 170px, $color-light-bg) repeat-x top left;
  }

  .c-featured-tiles__text {
    padding: 33px;
  }

  .c-featured-tiles__media {
    height: 160px;
    max-height: none;
  }

  .c-featured-tiles__media:before {
    display: none;
  }
}
