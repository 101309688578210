/* ==========================================================================
   #MEMBER
   ========================================================================== */

.c-member {
  padding: 40px 16px 25px;
  position: static;
  transition: visibility $transition-fast-out ease, opacity $transition-fast-out;
  pointer-events: auto;
  background-color: $color-white;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.2668);
}

.c-member.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.c-member__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.c-member__image {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin-right: 6px;
  flex-grow: 0;
  flex-shrink: 0;
}

.c-member__header-text {
  width: calc(100% - 94px);
  flex-grow: 1;
  flex-shrink: 1;
}

.c-member__header-buttons {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 24px;
}

.c-member__header-title {
  margin-bottom: 0;
}

.c-member__header-button {
  display: flex;
  width: 100%;
  margin-top: 13px;
  max-width: 320px;
}

.c-member__content {
  margin-bottom: 20px;
}

.c-member__meta {
  display: flex;
  margin-left: -30px;
  margin-bottom: 30px;
}

.c-member__meta-item {
  width: calc(50% - 30px);
  margin-left: 30px;
}

.c-member__publications-title {
  margin-bottom: 1em;
}

.c-member__publications {
  padding-bottom: 25px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

.c-member__members-title {
  margin-bottom: 1em;
  width: 100%;
}

.c-member__members-items {
  display: flex;
}

.c-member__view-all {
  margin-top: 30px;
}

.c-member__member-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin: auto auto 10px;
  display: block;
}

.c-member__member {
  margin-right: 25px;
  flex-shrink: 0;
}

.c-member__publications-more {
  margin-top: 15px;
}

@include bp-down(small) {
  .c-member__member:nth-child(n + 4) {
    display: none;
  }
}

@include bp-down(medium) {
  .c-member__publications.is-active .c-member__publications-more,
  .c-member__publications:not(.is-active) .c-research-tile:nth-child(n + 3) {
    display: none;
  }
}

@include bp(medium) {
  .c-member {
    padding: 45px 35px 30px;
  }

  .c-member__publications-more {
    margin-top: 22px;
  }

  .c-member__image {
    width: 112px;
    height: 112px;
    margin-right: 16px;
  }

  .c-member__header {
    flex-wrap: nowrap;
    margin-bottom: 30px;
  }

  .c-member__header-text {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 15px;
  }

  .c-member__header-buttons {
    flex-shrink: 1;
    flex-grow: 0;
    width: 144px;
    margin: 0;
  }

  .c-member__header-button {
    margin-top: 7px;
  }

  .c-member__header-button:first-child {
    margin-top: 0;
  }

  .c-member__content {
    margin-bottom: 25px;
  }

  .c-member__meta {
    margin-left: -90px;
  }

  .c-member__meta-item {
    margin-left: 90px;
    width: auto;
  }

  .c-member__members {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .c-member__members-title {
    width: 100%;
  }

  .c-member__view-all {
    margin-left: auto;
    margin-top: 15px;
  }

  .c-member__member:last-child {
    margin-right: 0;
  }
}

@include bp(large) {
  .c-member {
    padding: 77px 77px 70px;
  }

  .c-member__image {
    width: 142px;
    height: 142px;
    margin-right: 26px;
  }

  .c-member__header {
    margin-bottom: 60px;
  }

  .c-member__meta {
    margin-left: -110px;
  }

  .c-member__meta-item {
    margin-left: 110px;
    width: auto;
  }

  .c-member__meta-item-title {
    margin-bottom: 7px;
  }

  .c-member__publications-title {
    margin-bottom: 20px;
  }

  .c-member__publications {
    padding-bottom: 40px;
    margin-bottom: 25px;
  }

  .c-member__member {
    margin-right: 45px;
  }
}
