/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer__newsletter {
  background-color: $color-light-bg;
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-footer__newsletter-copy {
  text-align: center;
  max-width: 370px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__newsletter-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.c-footer__newsletter-column {
  position: relative;
  margin-bottom: 16px;
  display: flex;
}

.c-footer__newsletter-input {
  background-color: $color-white;
  padding: 14px 10px 13px;
  display: block;
  width: 100%;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;

  &::placeholder {
    color: #848a8f;
  }
}

.c-footer__newsletter-submit {
  font-size: 14px;
  padding: 11px 30px 10px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__top {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fbfbfb;
}

.c-footer__logo {
  display: inline-block;
  margin-bottom: 24px;
}

.c-footer__menus {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-bottom: 40px;
  margin-top: -1em;
}

.c-footer__menus a {
  color: $color-blue-darker;

  @include link-active-hover {
    color: $color-link-hover;
  }
}

.c-footer__bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  color: #9e9e9e;

  a {
    color: $color-navy-blue-darker;
  }
}

.c-footer__social-links {
  margin-left: -30px;
  margin-top: -10px;
}

.c-footer__social-links-link {
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
}

.c-footer__social-links-link--twitter .c-footer__social-links-icon {
  width: 25px;
  margin-bottom: -2px;
}

.c-footer__social-links-link--youtube .c-footer__social-links-icon {
  width: 30px;
}

.c-footer__newsletter-message {
  margin-top: 1em;
}

.c-footer__newsletter-message:empty {
  display: none;
}

@include bp(medium) {
  .c-footer__newsletter-column {
    margin-bottom: 5px;
  }

  .c-footer__newsletter-submit {
    margin-top: 30px;
    min-width: 142px;
  }

  .c-footer__menus {
    margin-left: 0;
    justify-content: space-between;
    width: calc(100% - 160px);
  }

  .c-footer__top-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .c-footer__social-links {
    display: flex;
    flex-wrap: wrap;
    width: #{2 * (30 + 30)}px;
    margin-left: 0;
  }

  .c-footer__social-links-link {
    margin-bottom: 28px;
  }
}

@include bp(large) {
  .c-footer__newsletter-inner {
    display: flex;
    align-items: center;
  }

  .c-footer__newsletter-copy {
    width: (316 / ($wrapper-width / 1px)) * 100%;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: left;
    padding-right: 20px;
    margin-bottom: 0;
  }

  .c-footer__newsletter-inputs {
    width: (($wrapper-width - 316px) / $wrapper-width) * 100%;
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    position: relative;
  }

  .c-footer__newsletter-column {
    margin-bottom: 0;
    margin-right: 15px;
    width: 440px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-footer__newsletter-submit {
    margin: 0;
    min-width: 166px;
  }

  .c-footer__top-inner {
    display: flex;
    align-items: flex-start;
  }

  .c-footer__top-columns {
    width: (($wrapper-width - 316px) / $wrapper-width) * 100%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-left: auto;
  }

  .c-footer__logo {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .c-footer__menus {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-left: -40px;
  }

  .c-footer__social-links {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;
    min-width: #{5 * (30 + 30)}px;
  }

  .c-footer__bottom {
    font-size: 14px;
  }

  .c-footer__newsletter-message {
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@include bp(xlarge) {
  .c-footer__menus {
    margin-left: -50px;
  }
}
