/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */

.c-breadcrumbs {
  font-size: 11px;
  margin-bottom: $block-bottom-margin * 1.3;
  display: flex;
  flex-wrap: wrap;
}

.c-breadcrumbs__item + .c-breadcrumbs__item:before {
  content: '|';
  display: inline-block;
  margin-left: 0.3em;
  margin-right: 0.3em;
}
