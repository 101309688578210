/* ==========================================================================
   #IMAGE STATS
   ========================================================================== */

.c-image-stats {
  background-color: $color-light-bg;
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-image-stats__title {
  margin-bottom: 30px;
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.c-image-stats__media {
  margin: 0;
}

.c-image-stats__image {
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.c-image-stats .c-stats {
  margin-bottom: 0;
}

@include bp(medium) {
  .c-image-stats {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .c-image-stats__title {
    width: 80%;
  }
}

@include bp(large) {
  .c-image-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .c-image-stats__media {
    width: 50%;
    max-width: 492px;
    padding-right: 20px;
  }

  .c-image-stats__stats {
    width: 50%;
    max-width: 444px;
    padding-left: 20px;
  }

  .c-image-stats__caption {
    text-align: center;
  }

  .c-image-stats__image {
    max-width: 100%;
  }

  .c-image-stats__title {
    margin-bottom: 50px;
  }
}

@include bp(xlarge) {
  .c-image-stats {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
