/* ==========================================================================
   #STATS
   ========================================================================== */

.c-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 2 * 116px;
  margin-bottom: $block-bottom-margin;
}

.c-stats__stat {
  font-size: 48px;
  font-weight: 300;
  line-height: 0.9;
}

.c-stats__item {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.c-stats > * {
  width: 116px;
}

.c-stats__description.c-stats__description {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
  padding-bottom: 0.4em;
}

.c-stats__description {
  font-size: 10px;
}

@include bp(small) {
  .c-stats__stat {
    font-size: 68px;
  }

  .c-stats {
    width: 200px * 2;
  }

  .c-stats > * {
    width: 200px;
  }

  .c-stats__description {
    font-size: 16px;
  }
}

@include bp-down(medium) {
  .c-stats > *:nth-child(n + 3) {
    border-top: 1px solid #c4cdd5;
  }

  .c-stats > *:nth-child(even) {
    border-left: 1px solid #c4cdd5;
  }
}

@include bp(medium) {
  .c-stats > * {
    flex-shrink: 0;
    flex-grow: 0;
    width: 25%;
    border-left: 1px solid #c4cdd5;
  }

  .c-stats > *:nth-child(4n - 3) {
    border-left: none;
  }

  .c-stats > *:nth-child(n + 5) {
    border-top: 1px solid #c4cdd5;
  }

  .c-stats {
    width: auto;
  }
}

@include bp(large) {
  .c-stats--matrix {
    width: 2 * 212px;
  }

  .c-stats--matrix .c-stats__item {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .c-stats--matrix.c-stats > * {
    border-left: 0;
    border-top: 0;
    width: 212px;
  }

  .c-stats--matrix.c-stats > *:nth-child(n + 3) {
    border-top: 1px solid #c4cdd5;
  }

  .c-stats--matrix.c-stats > *:nth-child(even) {
    border-left: 1px solid #c4cdd5;
  }
}
