/* ==========================================================================
   #BODY
   ========================================================================== */

body {
  padding-top: $header-height-small;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
  scroll-padding: calc(var(--admin-bar-height, 0) + var(--header-height, 0));

  @supports (--custom:property) {
    min-height: calc(var(--full-height) - var(--admin-bar-height));
  }
}

@include bp(medium) {
  body {
    padding-top: $header-height-medium;
  }
}

