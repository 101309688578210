/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background-color: $color-light-bg;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;

  @include css-var {
    top: var(--admin-bar-height);
  }
}

.c-header__inner {
  display: flex;
  align-items: center;
  height: $header-height-small;
}

.c-header__hamburger {
  position: relative;
  z-index: 12;
  margin-left: auto;

  &:not(.is-active):active {
    color: $color-orange;
  }
}

.c-header__logo {
  display: block;
}

.c-header__logo-image {
  display: block;
  width: 60px;
  height: 22px;
}

@include bp(medium) {
  .c-header__inner {
    height: $header-height-medium;
  }

  .c-header__logo-image {
    width: 92px;
    height: 33px;
  }
}

@include bp-down(xlarge) {
  .c-header__menu {
    background-color: $color-orange;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 11;
    padding: 95px 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 437px;
    transform: translateX(100%);
    transition: transform $transition-fast-out ease;
  }

  .c-header__menu.is-active {
    transform: none;
    transition-duration: $transition-fast-in;
  }

  .c-header__hamburger {
    padding: 10px;
    margin-right: -10px;
  }



  @include bp(360px) {
    .c-header__menu.is-active {
      box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
    }
  }

  @include bp(medium) {
    .c-header__menu {
      padding: 100px 74px;
    }
  }
}

@include bp(xlarge) {
  .c-header {
    background-color: $color-white;
  }

  .c-header__menu {
    display: flex;
    align-self: stretch;
    align-items: stretch;
    margin-left: auto;
  }

  .c-header__logo-image {
    width: 134px;
    height: 48px;
  }
}

.c-header__search {
  position: relative;
  height: 30px;
  align-self: center;
  padding: 5px 10px;
  margin-left: auto;

  &--mobile {
    display: none;
  }

  &--desktop {
    display: block;
  }

  @include bp-down(xlarge) {
    position: absolute;
    top: 20px;
    right: 110px;
    padding: 0;
    height: 25px;
    width: 25px;

    &--mobile {
      display: block;
    }

    &--desktop {
      display: none;
    }
  }

  @include bp-down(medium-small) {
    top: 14px;
    right: 70px;
    padding: 0;
    height: 20px;
    width: 20px;
  }
}

.c-header__search-icon {
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
}

.c-header__search-form {
  position: absolute;
  top: calc(100% + 17px);
  right: 0;
  display: none;
  background-color: $color-white;

  @include bp-down(xlarge) {
    position: fixed;
    z-index: 10;
    top: 65px;
    left: 0;
    background-color: $color-white;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.2);

    .admin-bar & {
      top: 97px;
    }
  }

  @media (max-width: 782px) {
    .admin-bar & {
      top: 111px;
    }
  }

  @include bp-down(medium-small) {
    top: 46px;

    .admin-bar & {
      top: 92px;
    }
  }

  &.is-open {
    display: block;
  }

  .searchform {
    > div {
      display: flex;
    }

    @include bp(xlarge) {
      padding: 0;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    input {
      border-bottom: 3px solid rgba($color-orange, 1);
      transition: all $transition-slow-in ease;
      font-size: 14px;
      display: block;

      &::placeholder {
        color: $color-gray-dark;
      }

      &[type="text"] {
        padding: 8px 20px 8px 8px;

        @include bp-down(xlarge) {
          width: 100%;
          padding: 12px 20px;
        }

        @include bp-down(medium-small) {
          padding: 10px 16px;
        }
      }

      &[type="submit"] {
        padding: 8px 8px;
        cursor: pointer;

        &:hover {
          background-color: $color-orange;
          color: $color-white;
        }

        @include bp-down(xlarge) {
          padding: 12px;
        }

        @include bp-down(medium-small) {
          padding: 10px;
        }
      }
    }
  }
}
