/* ==========================================================================
   #DISPLAY
   ========================================================================== */

@include bp-down(medium) {
  .u-hide-small {
    display: none;
  }
}

@include bp(medium) {
  .u-hide-medium {
    display: none;
  }
}

@include bp(xlarge) {
  .u-hide-xlarge {
    display: none;
  }
}

@include bp(full) {
  .u-hide-full {
    display: none;
  }
}

@include bp-down(medium) {
  .u-hide-on-small {
    display: none;
  }
}

@include bp-between(medium, xlarge) {
  .u-hide-on-medium {
    display: none;
  }
}

@include bp(xlarge) {
  .u-hide-on-xlarge {
    display: none;
  }
}

@include bp-down(medium) {
  .u-show-medium {
    display: none;
  }
}

@include bp-down(large) {
  .u-show-large {
    display: none;
  }
}

@include bp-down(xlarge) {
  .u-show-xlarge {
    display: none;
  }
}

@include bp-down(full) {
  .u-show-full {
    display: none;
  }
}

@include bp-down(medium) {
  .u-show-small {
    display: none;
  }
}
