/* ==========================================================================
   #SUBMENU
   ========================================================================== */

.c-submenu {
  margin-bottom: $block-bottom-margin;
  border-bottom: 1px solid #c4cdd5;
  word-break: normal;
}

.c-submenu.is-style-vertical {
  border: none;
}

.c-submenu__items {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  margin-bottom: -1px;
  max-width: 100%;
}

.c-submenu__items > * {
  margin-left: 0;
  margin-right: 0;
}

.c-submenu:not(.is-style-vertical) .c-submenu__items > * {
  min-width: 120px;
  flex-basis: calc(100% / 5);
  flex-shrink: 1;
  flex-grow: 1;
}

.c-submenu--many:not(.is-style-vertical) .c-submenu__items > * {
  min-width: 0;
  flex-basis: calc(100% / 6);
  padding-left: 10px;
  padding-right: 10px;
}

.c-submenu.is-style-vertical .c-submenu__items {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: visible;
  margin-bottom: 0;
}

.c-submenu__item {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px 20px;
  color: $color-blue-darkest;
  border-bottom: 2px solid transparent;
  transition: color $transition-fast-in ease, border-color $transition-fast-in ease;

  @include link-active-hover {
    border-color: $color-orange;
  }
}

.c-submenu.is-style-vertical .c-submenu__item {
  padding: 0;
  border: none;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
}

.c-submenu.is-style-vertical .c-submenu__items > * {
  margin-bottom: (10/14) * 1em;
}

.c-submenu.is-style-vertical .c-submenu__items > *:last-child {
  margin-bottom: 0;
}

@include bp(medium) {
  .c-submenu__item {
    padding: 15px 20px;
    border-width: 3px;
  };
}

@include bp(large) {
  .c-submenu__items {
    overflow: visible;
  }
}

@include bp(xlarge) {
  .c-submenu__item {
    padding: 18px 30px;
  };

  .c-submenu--many:not(.is-style-vertical) .c-submenu__items > * {
    padding-left: 20px;
    padding-right: 20px;
    flex-basis: auto;
  }
}

@include bp(large) {
  .c-submenu__item {
    white-space: nowrap;
  }
}
