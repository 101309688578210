/* ==========================================================================
   #RESOURCES
   ========================================================================== */

.c-resources {
  margin-bottom: $block-bottom-margin;
}

.c-resources__item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  display: block;
  margin-bottom: 15px;
}

.c-resources__item-bar {
  color: $color-blue-darkest;
  border: none;
  border-top: 4px solid currentColor;
  margin: 0;
  width: 100%;
}

.c-resource__item-media {
  position: relative;
  padding-top: (103/259) * 100%;
}

.c-resources__item-thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-resources__item-content {
  padding: 10px 7px;
}

.c-resources__item-title {
  margin-bottom: 7px;
  color: #212b36;
}

.c-resources__items {
  margin-bottom: -15px;
}

@include bp-down(medium) {
  .c-resources__item-title {
    font-size: 18px;
  }

  .c-resources__item:not(.is-active):nth-child(n + 4) {
    display: none;
  }
}

@include bp-down(large) {
  .c-resources__item:not(.is-active):nth-child(n + 7) {
    display: none;
  }
}

@include bp(medium) {
  .c-resources__item-bar {
    border-width: 5px;
  }

  .c-resources__items {
    margin-left: -18px;
    margin-bottom: -18px;
    display: flex;
    flex-wrap: wrap;
  }

  .c-resources__item {
    margin-bottom: 22px;
    margin-left: 18px;
    width: calc(50% - 18px);
  }

  .c-resources .c-filter__header {
    margin-bottom: 45px;
  }

  .c-resources__item-content {
    padding: 16px 26px;
  }
}

@include bp(large) {
  .c-resources__item-bar {
    border-width: 6px;
  }

  .c-resources__items {
    margin-left: -21px;
    margin-bottom: -31px;
  }

  .c-resources__item {
    margin-bottom: 31px;
    margin-left: 21px;
    width: calc(100% / 3 - 21px);
  }

  .c-resources__item:not(.is-active):nth-child(n + 10) {
    display: none;
  }
}

@include bp(xlarge) {
  .c-resources .c-filter__header {
    margin-bottom: 80px;
  }
}
