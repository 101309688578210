/* ==========================================================================
   #HAMBURGER
   ========================================================================== */

.o-hamburger__wrapper {
  display: block;
  width: 18px;
  height: 16px;
  position: relative;
  line-height: 1;
  margin: auto;
}

.o-hamburger__bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  opacity: 1;
  left: 0;
  transform: none;
  transition: transform 0.25s ease, width 0.25s;
  transform-origin: center center;
  border-radius: 1px;
}

.o-hamburger__bar:nth-child(1) {
  top: 0;
}

.o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
  top: 7px;
  width: 13px;
  left: auto;
  right: 0;
}

.o-hamburger__bar:nth-child(4) {
  top: 14px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(1) {
  transform: scaleX(0) translateY(9px);
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(2) {
  transform: rotate(45deg) translate(2px, -2px);
  width: 1.2 * 18px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(3) {
  transform: rotate(-45deg) translate(2px, 2px);
  width: 1.2 * 18px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(4) {
  transform: scaleX(0) translateY(-9px);
}

@include bp(medium) {
  .o-hamburger__wrapper {
    width: 27px;
    height: 23px;
  }

  .o-hamburger__bar {
    height: 3px;
  }

  .o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
    top: 10px;
    width: 19px;
  }

  .o-hamburger__bar:nth-child(4) {
    top: 20px;
  }

  .o-hamburger.is-active .o-hamburger__bar:nth-child(1) {
    transform: scaleX(0) translateY(11px);
  }

  .o-hamburger.is-active .o-hamburger__bar:nth-child(2) {
    transform: rotate(45deg) translate(4px, -4px);
    width: 1.2 * 27px;
  }

  .o-hamburger.is-active .o-hamburger__bar:nth-child(3) {
    transform: rotate(-45deg) translate(4px, 4px);
    width: 1.2 * 27px;
  }

  .o-hamburger.is-active .o-hamburger__bar:nth-child(4) {
    transform: scaleX(0) translateY(-11px);
  }
}
