/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2 * $block-bottom-margin;
}

.alignnone {
  margin: 0 $block-bottom-margin $block-bottom-margin 0;
}

.aligncenter {
  margin: 0 auto $block-bottom-margin;
}

.alignright {
  float: right;
  margin: 0 0 $block-bottom-margin $block-bottom-margin;
}

.alignleft {
  float: left;
  margin: 0 $block-bottom-margin $block-bottom-margin 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5 * $block-bottom-margin;
}
