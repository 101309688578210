///* ========================================================================
//   #HEIGHT
//   ======================================================================== */

@mixin height-100vh() {
  height: calc(100vh - #{$header-height-small});

  @supports (--custom:property) {
    height: calc(var(--full-height) - #{$header-height-small} - var(--admin-bar-height));
  }

  @include bp(medium) {
    & {
      height: calc(100vh - #{$header-height-medium});

      @supports (--custom:property) {
        height: calc(var(--full-height) - #{$header-height-medium} - var(--admin-bar-height));
      }
    }
  }
}

@mixin min-height-100vh() {
  min-height: calc(100vh - #{$header-height-small});

  @supports (--custom:property) {
    min-height: calc(var(--full-height) - #{$header-height-small} - var(--admin-bar-height));
  }

  @include bp(medium) {
    & {
      min-height: calc(100vh - #{$header-height-medium});

      @supports (--custom:property) {
        min-height: calc(var(--full-height) - #{$header-height-medium} - var(--admin-bar-height));
      }
    }
  }
}

@mixin max-height-100vh() {
  max-height: calc(100vh - #{$header-height-small});

  @supports (--custom:property) {
    max-height: calc(var(--full-height) - #{$header-height-small} - var(--admin-bar-height));
  }

  @include bp(medium) {
    & {
      max-height: calc(100vh - #{$header-height-medium});

      @supports (--custom:property) {
        max-height: calc(var(--full-height) - #{$header-height-medium} - var(--admin-bar-height));
      }
    }
  }
}
