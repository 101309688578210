/* ==========================================================================
   #ICON TILES
   ========================================================================== */

.c-icon-tiles {
  display: flex;
}

.c-icon-tiles__items {
  margin-bottom: -50px;
}

.c-icon-tiles__items > * {
  margin-bottom: 50px;
}

.c-icon-tiles__image {
  margin-bottom: 9px;
  display: block;
}

.c-icon-tiles__title {
  font-size: inherit;
  margin-bottom: 0;
}

.c-icon-tiles__text {
  margin-bottom: 10px;
}

.c-icon-tiles__button {
  min-width: 140px;
}

@include bp(medium) {
  .c-icon-tiles__title {
    margin-bottom: 8px;
  }

  .c-icon-tiles__text {
    margin-bottom: 16px;
  }

  .c-icon-tiles__items {
    display: flex;
    margin-bottom: -45px;
    flex-wrap: wrap;
  }

  .c-icon-tiles__items > * {
    width: calc(50% - 35px);
    margin-right: 35px;
    margin-bottom: 45px;
  }

  .c-icon-tiles__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .c-icon-tiles__button {
    margin-top: auto;
  }
}

@include bp(large) {
  .c-icon-tiles__image {
    margin-bottom: 15px;
  }

  .c-icon-tiles__items {
    margin-left: -24px;
  }

  .c-icon-tiles__items > * {
    width: calc(25% - 24px);
    margin-left: 24px;
    margin-right: 0;
  }
}
