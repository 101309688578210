/* ==========================================================================
   #IMAGE SLIDES
   ========================================================================== */

.c-image-slides {
  margin-bottom: $block-bottom-margin;
}

.c-image-slides__items {
  position: relative;
}

.c-image-slides__item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  transition: visibility $transition-fast-out ease, opacity $transition-fast-out ease;
  opacity: 0;
  visibility: hidden;
}

.c-image-slides__item.is-active {
  opacity: 1;
  visibility: visible;
  position: relative;
  z-index: 1;
  transition-duration: $transition-fast-in, $transition-fast-in;
}

.c-image-slides__image {
  display: block;
  margin: auto;
  width: 607px;
}
