///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin wrapper {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  max-width: $wrapper-width;

  @include bp(medium) {
    & {
      width: calc(100% - 110px);
    }
  }
}

@mixin wrapper-padding {
  padding-left: 30px;
  padding-right: 30px;

  @include bp(medium) {
    & {
      padding-left: 55px;
      padding-right: 55px;
    }
  }

  @include bp($wrapper-width + 110px) {
    & {
      padding-right: calc(50% - #{$wrapper-width / 2});
      padding-left: calc(50% - #{$wrapper-width / 2});
    }
  }
}

@mixin anti-wrapper {
  margin-left: -30px;
  margin-right: -30px;

  @include bp(medium) {
    margin-left: -55px;
    margin-right: -55px;
  }

  @include bp($wrapper-width + 110px) {
    width: 100vw;
    margin: 0;
  }
}
