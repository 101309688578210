/* ==========================================================================
   #FILTER
   ========================================================================== */

.c-filter {
  padding-top: 35px;
  padding-bottom: 45px;
}

.c-filter__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 33px;
}

.c-filter__filters {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.c-filter__form {
  margin-top: 20px;
  width: 100%;
}

.c-filter__title {
  margin-bottom: 0;
  max-width: calc(100% - 60px);
  margin-right: 10px;
}

.c-filter__all {
  margin-bottom: 0.3em;
  border: none;
  background-color: transparent;
}

.c-filter__select {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  cursor: default;
}

.c-filter__select-value {
  border-bottom: 6px solid $color-orange;
  padding: 0.2em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color $transition-fast-in ease;
}

.c-filter__select.is-active .c-filter__select-value {
  border-color: #e02020;
}

.c-filter__select-chevron {
  flex-shrink: 0;
  flex-grow: 0;
  transition: fill $transition-fast-in ease;
}

.c-filter__select.is-active .c-filter__select-chevron {
  fill: $color-orange;
}

.c-filter__select-options {
  background-color: $color-orange;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.247651);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 16px;
  padding: 14px 15px;
  transition: opacity $transition-fast-out ease, visibility $transition-fast-out ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.c-filter__select.is-active .c-filter__select-options {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition-duration: $transition-fast-in, $transition-fast-in;
  pointer-events: auto;
}

.c-filter__select-option {
  margin-bottom: 1em;
}

.c-filter__select-option:last-child {
  margin-bottom: 0;
}

.c-filter__select-button {
  text-align: left;
  cursor: pointer;
}

.c-filter__select-button.is-active {
  font-weight: 700;
}

.c-filter__search {
  width: 100%;
  position: relative;
}

.c-filter__search-input {
  border-bottom: 3px solid rgba($color-orange, 0.26);
  transition: border-color $transition-fast-in ease;
  padding: 8px 20px 8px 0;
  width: 100%;
  display: block;

  &::placeholder {
    color: $color-gray-dark;
  }
}

.c-filter__search-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  border: none;
  background-color: transparent;
}

.c-filter__search-input:focus {
  border-color: $color-orange;
}

.c-filter__search-icon {
  width: 12px;
  height: 12px;
  transition: fill $transition-fast-in ease;
  fill: rgba($color-black, 0.4)
}

.c-filter__items {
  position: relative;
}

.c-filter__items:after {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba($color-white, 0.75);
  transition: visibility $transition-fast-out ease, opacity $transition-fast-out ease;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  top: -10px;
  left: -10px;
}

.c-filter.is-loading .c-filter__items:after {
  opacity: 1;
  visibility: visible;
  transition: $transition-fast-in, $transition-fast-in;
}

.c-filter__search-submit {
  @include link-active-hover {
    .c-filter__search-icon {
      fill: $color-black;
    }
  }
}

.c-filter__more {
  margin-top: 25px;
}

.c-filter.no-more .c-filter__more {
  display: none;
}

.c-filter__select-value {
  white-space: nowrap;
}

@include bp(medium) {
  .c-filter {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .c-filter__title {
    margin-right: 20px;
  }

  .c-filter__form {
    margin-top: 30px;
  }

  .c-filter__filters {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin-left: -20px;
  }

  .c-filter__select {
    margin-left: 20px;
  }

  .c-filter__select--member-type {
    min-width: (121/687) * 100%;
  }

  .c-filter__select--member-thematic-area {
    min-width: (153/687) * 100%;
  }

  .c-filter__select--member-thematic-area {
    min-width: (153/687) * 100%;
  }

  .c-filter__select--research-region {
    min-width: (169/687) * 100%;
  }

  .c-filter__select--member-location {
    min-width: (171/687) * 100%;
  }

  .c-filter__header {
    margin-bottom: 35px;
  }

  .c-filter__more {
    margin-top: 40px;
  }

  .c-filter__filters {
    margin-bottom: 15px;
  }

  .c-filter__search-submit {
    width: 32px;
  }

  .c-filter__search-icon {
    width: 14px;
    height: 13px;
  }

  .c-filter__search-input {
    padding-right: 30px;
  }
}

@include bp(xlarge) {
  .c-filter {
    padding-bottom: 80px;
  }

  .c-filter__filters {
    margin-left: -32px;
    min-width: 0;
  }

  .c-filter__select {
    width: 188px;
    margin-left: 32px;
    min-width: auto;
  }

  .c-filter__search {
    width: calc(100% - #{(188 + 32) * 4}px);
    max-width: 322px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-filter__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .c-filter__select-value {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
