/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-underlined {
  text-decoration: underline;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-nowrap {
  white-space: nowrap;
}

.u-fsz-14 {
  font-size: 14px;
}

.u-fsz-bigger {
  @include bigger;
}

.u-fsz-small {
  @include small;
}

.u-fsz-smaller {
  @include smaller;
}

.u-fsz-smallest {
  @include smallest;
}

.u-fsz-12-14 {
  font-size: 12px;

  @include bp(xlarge) {
    font-size: 14px;
  }
}

.u-fsz-12-16 {
  font-size: 12px;

  @include bp(medium) {
    font-size: 16px;
  }
}

.u-fsz-11-16 {
  font-size: 11px;

  @include bp(medium) {
    font-size: 16px;
  }
}
