/* ==========================================================================
   #RELATED RESOURCES
   ========================================================================== */

.c-related-resources {
  margin-bottom: $block-bottom-margin;
}

.c-related-resources .c-research-tile.is-hidden {
  display: none;
}

.c-related-resources__view-all.c-related-resources__view-all {
  margin-top: 15px;
}

@include bp(medium) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 27px;
  }
}

@include bp(large) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 45px;
  }
}

@include bp(xlarge) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 50px;
  }
}
