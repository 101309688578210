/* ==========================================================================
   #SECTION
   ========================================================================== */

.c-section {
  @include section;
}

.c-section__columns > * {
  flex-basis: auto;
}

.c-section__column:last-child > *:last-child {
  margin-bottom: 0;
}

@include bp-down(medium) {
  .c-section__columns--reverse {
    flex-direction: column-reverse;
    display: flex;
  }

  .c-section__columns:not(.c-section__columns--reverse):last-child .wp-block-column:last-child > :last-child {
    margin-bottom: 0;
  }

  .c-section__columns.c-section__columns--reverse:last-child .wp-block-column:first-child > :last-child {
    margin-bottom: 0;
  }
}

@include bp(medium) {
  .c-section__columns:last-child .wp-block-column:last-child > :last-child {
    margin-bottom: 0;
  }

  .c-section__columns.is-resource-row {
    margin-bottom: 50px;
  }
}

@include bp(xlarge) {
  .c-section__columns {
    display: flex;
    justify-content: space-between;
  }

  .c-section__columns--reverse {
    flex-direction: row-reverse;
  }

  .c-section__columns:not(.is-header-row) > *:first-child,
  .c-section__columns.is-header-row > *:last-child {
    width: 364/($wrapper-width / 1px) * 100%;
    flex-basis: auto;
  }

  .c-section__columns:not(.is-header-row) > *:last-child,
  .c-section__columns.is-header-row > *:first-child {
    width: 796/($wrapper-width / 1px) * 100%;
    flex-basis: auto;
  }

  .c-section__columns.c-section__columns > * {
    margin: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
