/* ==========================================================================
   #RESEARCH TILES
   ========================================================================== */

.c-research-tiles {
  margin-bottom: -15px;
}

.c-research-tiles > * {
  margin-left: 0;
}

@include bp(medium) {
  .c-research-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -22px;
  }

  .c-research-tiles > * {
    width: calc(50% - 10px);
    margin-bottom: 22px;
    margin-left: 0;
  }
}

@include bp(large) {
  .c-research-tiles > * {
    width: calc(50% - 14px);
    margin-bottom: 22px;
  }
}
