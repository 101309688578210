/* ==========================================================================
   #MARGIN
   ========================================================================== */

.u-no-last-margin > *:last-child {
  margin-bottom: 0;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-small {
  margin-bottom: 20px;
}

.u-mb-medium {
  margin-bottom: 30px;
}

.u-mb-large {
  margin-bottom: 40px;
}

@include bp(medium) {
  .u-mb-small {
    margin-bottom: 30px;
  }

  .u-mb-medium {
    margin-bottom: 40px;
  }

  .u-mb-large {
    margin-bottom: 50px;
  }
}
@include bp(large) {
  .u-mb-small {
    margin-bottom: 40px;
  }

  .u-mb-medium {
    margin-bottom: 50px;
  }

  .u-mb-large {
    margin-bottom: 60px;
  }
}
