/* ==========================================================================
   #INPUT
   ========================================================================== */

button, input, textarea, select {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  outline: none;
  letter-spacing: inherit;
  text-transform: inherit;
}

input, textarea, select, button {
  line-height: normal;
}

button {
  cursor: pointer;
  transition: color $transition-fast-in ease, background-color $transition-fast-in ease, border-color $transition-fast-in ease;
}

input, textarea {
  &::placeholder {
    opacity: 1;
    color: currentColor;
  }
}
