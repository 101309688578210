.c-pagination {
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
}

.c-pagination__page {
  &--prev svg {
    transform: rotate(90deg);
  }

  &--next svg {
    transform: rotate(-90deg);
  }
}

.c-pagination__pages {
  display: flex;
  gap: 8px;

  .c-pagination__page {
    padding: 2px 5px;
    background-color: rgba($color-gray, 0.3);
    color: $color-black;
    transition: all $transition-slow-out ease;

    &:hover,
    &--current {
      background-color: $color-orange;
      color: $color-white;
    }
  }
}
