/* ==========================================================================
   #HERO
   ========================================================================== */


.c-hero {
  position: relative;
  background-color: #343c45;
  display: flex;
  align-items: stretch;
}

.c-hero:before {
  content: '';
  width: 0;
  height: 380px;
}

.c-hero__inner {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  max-width: 600px;
}

.c-hero__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-hero__title {
  margin-bottom: 15px;
  color: inherit;
}

.c-hero__title:last-child {
  margin-bottom: 0;
}

.c-hero__button {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: -17px;
  left: 0;
  padding: 0;
}

.c-hero__button-icon {
  width: 14px;
  height: 9px;
  display: block;
  margin: auto;
}

@include bp(medium) {
  .c-hero:before {
    height: 420px;
  }

  .c-hero__button {
    width: 55px;
    height: 55px;
    bottom: -27px;
  }

  .c-hero__button-icon {
    width: 21px;
    height: 14px;
  }
}

