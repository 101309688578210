/* ==========================================================================
   #COVER
   ========================================================================== */

.c-cover {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $color-navy-blue-darker;
}

.c-cover__title {
  color: inherit;
  margin-bottom: 15px;
}

.c-cover__text {
  margin-bottom: 30px;
  max-width: 694px;
  margin-left: auto;
  margin-right: auto;
}

@include bp(medium) {
  .c-cover {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .c-cover__text {
    margin-bottom: 50px;
  }
}

@include bp(large) {
  .c-cover {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .c-cover__text {
    margin-bottom: 20px;
  }
}
