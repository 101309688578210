/* ==========================================================================
   #RESEARCH TILE
   ========================================================================== */

.c-research-tile {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 15px 12px 18px;
  position: relative;
  margin-bottom: 15px;
}

.c-research-tile__bar {
  border: none;
  border-top: 5px solid currentColor;
  width: 100%;
  margin: 0;
  color: #aca9a7;
  position: absolute;
  left: 0;
  top: 0;
}

.c-research-tile__type {
  margin-bottom: 0;
}

.c-research-tile__title {
  margin-bottom: 10px;
}

.c-research-tile__footer {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
}

.c-research-tile__footer-item:after {
  content: '•';
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.c-research-tile__footer-item:last-child:after {
  display: none;
}

.c-research-tile__title {
  max-height: (2 * (19/16)) + em;
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@include bp(medium) {
  .c-research-tile {
    padding: 20px 16px 12px 21px;
  }

  .c-research-tile__bar {
    height: 6px;
  }

  .c-research-tile__title:last-child {
    min-height: (2 * (19/16)) + em;
  }
}

@include bp(large) {
  .c-research-tile {
    padding: 20px 22px 14px 26px;
  }
}
