/* ==========================================================================
   #PAGE
   ========================================================================== */

// stylelint-disable
:root {
  --full-height: 100vh;
  --initial-height: 100vh;
  --scroll-width: 0;
  --admin-bar-height: 0px;
}
// stylelint-enable

html {
  color: $color-text;
  background-color: $color-white;
  font-family: $font-sans-serif;
  font-size: 14px;
  line-height: (18/14);
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}

// stylelint-disable-next-line
html.has-admin-bar {
  --admin-bar-height: 32px;
}

@include bp-down(xlarge) {
  html {
    overflow-x: hidden;
  }
}

@include bp(medium) {
  html {
    font-size: 16px;
    line-height: (22/16);
  }
}

