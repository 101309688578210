/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-no-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.u-no-overscroll-y {
  overscroll-behavior-y: none;
  -ms-scroll-chaining: none;
}

.u-no-overscroll-x {
  overscroll-behavior-x: none;
  -ms-scroll-chaining: none;
}

