/* ==========================================================================
   #DESIGN REGISTRY
   ========================================================================== */

.c-design-registry__sort {
  position: relative;
  display: inline-block;
  width: auto;
  vertical-align: middle;
  appearance: none;
  letter-spacing: 0.042em;
  padding-right: 20px;
  background: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M2 2L7.5 8L13 2' stroke='%231e262f' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E") center right / 10px 6px no-repeat;

  &:focus {
    outline: 0;

    &::-ms-value {
      color: inherit;
      background-color: $color-white;
    }
  }

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: none;
  }
}

.c-design-registry__count {
  align-self: center;
  width: 100%;
}

.c-design-registry__search-input {
  font-size: 14px;
  border-bottom: 3px solid rgba($color-orange, 0.3);
  transition: border-color $transition-fast-in ease;
  padding: 10px 30px 10px 0;
  display: block;
  width: 100%;
}

.c-design-registry__search-input:focus {
  border-color: $color-orange;
}

.c-design-registry__search-input::placeholder {
  color: #5f5e5e;
}

.c-design-registry__search {
  width: 320px;
  max-width: 100%;
  position: relative;
  align-self: center;
  margin-left: auto;
}

.c-design-registry__submit {
  position: absolute;
  right: 0;
  height: 100%;
  width: 32px;
  top: 0;
  color: #707070;

  @include link-active-hover {
    color: $color-navy-blue-darker;
  }
}

.c-design-registry__header {
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.c-design-registry__filters {
  margin-bottom: 30px;
}

.c-design-registry__item {
  box-shadow: 0 2px 10px rgba($color-black, 0.15);
  margin-bottom: 10px;
  padding: 8px 12px;
}

.c-design-registry__item-title {
  margin-bottom: 4px;
  line-height: 1;
}

.c-design-registry__item-authors {
  font-size: 14px;
  margin-bottom: 0;
}

.c-design-registry__item-filters {
  font-size: 14px;
}

.c-design-registry__item-links {
  margin-bottom: 0;
  overflow: hidden;
  transition: max-height $transition-slow-in ease;
  max-height: 0;
}

.c-design-registry__item-links:before {
  display: block;
  height: 10px;
  content: '';
}

.c-design-registry__item-link {
  margin-bottom: 0;
}

.c-design-registry__item-accordion {
  display: flex;
  flex-direction: column;
}

.c-design-registry__item-toggle {
  margin-left: auto;
  margin-right: auto;
  text-indent: -200vw;
  overflow: hidden;
}

.c-design-registry__item-toggle > * {
  display: flex;
  width: 34px;
  height: 33px;
  overflow: hidden;
  position: relative;

  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: currentColor transparent transparent transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -3px;
    transition: transform $transition-fast-in ease;
  }
}

.c-design-registry__item-accordion.is-active .c-design-registry__item-toggle > *:after {
  transform: rotate(180deg);
}

.c-design-registry__item-accordion.is-active .c-design-registry__item-links {
  max-height: 6em * (18/14);
}

.c-design-registry__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.c-design-registry__pagination > * {
  display: block;
  border: 1px solid #ddd;
  padding: 0.4em 0.7em;
  transition: background-color $transition-fast-in ease, color $transition-fast-in ease;
  margin-right: -1px;

  @include link-active-hover {
    color: $color-white;
    background-color: $color-blue-darker;
  }

  &:last-child {
    margin-right: 0;
  }
}

.c-design-registry__pagination > .current {
  color: $color-white;
  background-color: $color-blue-darker;
}

.c-design-registry__item-footer {
  color: #9b9b9b;
}

.c-design-registry__help {
  position: absolute;
  right: -20px;
  top: calc(100% - 20px);
  padding: 25px;
  width: calc(100% + 40px);
  max-width: 560px;
  z-index: 1;
  background-color: $color-white;
  box-shadow: 0 2px 10px rgba($color-black, 0.15);
  transition: opacity $transition-fast-in ease, visibility $transition-fast-in ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.c-design-registry__help-trigger {
  width: 40px;
  height: 40px;
  color: #707070;

  @include link-click() {
    @at-root & + .c-design-registry__help {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}

@include bp-down(medium) {
  .c-design-registry__count {
    margin-bottom: 20px;
  }
}

@include bp(medium) {
  .c-design-registry__help {
    right: 0;
    width: 100%;
    top: calc(100% - 15px);
  }

  .c-design-registry__count {
    width: auto;
  }

  .c-design-registry__header {
    justify-content: space-between;
  }
}
