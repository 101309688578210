/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  color: $color-headings;
  margin-top: 0;
}

h1, h2, h3, h4,
.o-h1, .o-h2, .o-h3, .o-h4 {
  font-weight: 600;
}

h5, h6,
.o-h5, .o-h6 {
  font-weight: 400;
}

h1,
.o-h1 {
  @include h1;
}

h2,
.o-h2 {
  @include h2;
}

h3,
.o-h3 {
  @include h3;
}

h4,
.o-h4 {
  @include h4;
}

h5,
.o-h5 {
  @include h5;
}

h6,
.o-h6 {
  @include h6;
}
