@charset "UTF-8";
/*
  Project: EGAP
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Transitions
   ========================================================================== */
/* Sizes
   ========================================================================== */
/* Spaces
   ========================================================================== */
/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/* ==========================================================================
   #CSS VAR
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
/* ==========================================================================
   #WORDPRESS
   ========================================================================== */
#wpadminbar {
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 599.98px) {
  #wpadminbar #wp-admin-bar-wpseo-menu {
    display: none;
  }
}

.has-h-1-font-size {
  font-size: 34px;
  line-height: 1.14706;
}

@media (min-width: 640px) {
  .has-h-1-font-size {
    font-size: 64px;
    line-height: 1.06667;
  }
}

.has-h-2-font-size {
  font-size: 34px;
  line-height: 1.14706;
}

@media (min-width: 640px) {
  .has-h-2-font-size {
    font-size: 42px;
    line-height: 1.04762;
  }
}

.has-h-3-font-size {
  font-size: 28px;
  line-height: 1.07143;
}

@media (min-width: 640px) {
  .has-h-3-font-size {
    font-size: 40px;
    line-height: 1.1;
  }
}

.has-h-4-font-size {
  font-size: 18px;
  line-height: 1.11111;
}

@media (min-width: 640px) {
  .has-h-4-font-size {
    font-size: 28px;
    line-height: 1.14286;
  }
}

.has-h-5-font-size {
  font-size: 16px;
  line-height: 1.1875;
}

@media (min-width: 640px) {
  .has-h-5-font-size {
    font-size: 20px;
    line-height: 1.15;
  }
}

.has-h-6-font-size {
  font-size: 14px;
}

@media (min-width: 640px) {
  .has-h-6-font-size {
    font-size: 16px;
  }
}

.wp-block-quote {
  max-width: 886px;
  border-left: 5px solid #ff5900;
  padding-left: 10px;
}

.wp-block-quote cite,
.wp-block-quote {
  font-style: normal;
}

.has-gray-color {
  color: #5f5e5e;
}

.has-orange-color {
  color: #ff5900;
}

.has-white-color {
  color: #fff;
}

.has-black-color {
  color: #000;
}

.has-gray-background-color {
  background-color: #5f5e5e;
}

.has-orange-background-color {
  background-color: #ff5900;
}

.has-white-background-color {
  background-color: #fff;
}

.has-black-background-color {
  background-color: #000;
}

.wp-block-quote .wp-block-quote__citation strong,
.wp-block-quote cite strong {
  color: #ff5900;
  font-size: 12px;
}

@media (min-width: 640px) {
  .wp-block-quote .wp-block-quote__citation strong,
  .wp-block-quote cite strong {
    font-size: 15px;
  }
}

.wp-block-quote p {
  line-height: 1.71429;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: none;
  height: 0;
  border-bottom: 1px solid currentColor;
}

.wp-block-separator {
  border: none;
  border-bottom: 1px solid currentColor;
  height: 0;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: none;
}

.wp-block-separator:not(.has-text-color) {
  color: #c4cdd5;
}

.wp-block-quote a,
figcaption a {
  color: #234067;
  text-decoration: underline;
}

html.no-touch .wp-block-quote a:hover, html.no-touch
figcaption a:hover {
  color: #fa6400;
  text-decoration: underline;
}

.wp-block-quote a:active,
figcaption a:active {
  color: #fa6400;
  text-decoration: underline;
}

.is-style-nowrap {
  white-space: nowrap;
}

@media (min-width: 640px) {
  .wp-block-quote {
    padding-left: 20px;
    margin-top: 1.95rem;
    margin-bottom: 1.95rem;
  }
  .wp-block-quote p {
    font-size: 25px;
    line-height: 1.28;
  }
  .wp-block-quote .wp-block-quote__citation,
  .wp-block-quote cite {
    font-size: 18px;
  }
  .wp-block-separator {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

@media (min-width: 1024px) {
  .wp-block-separator {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .wp-block-quote {
    margin-left: 6.78617%;
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
    padding-left: 25px;
  }
  .c-section__columns > .wp-block-column:first-child .wp-block-quote {
    max-width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1400px) {
  .wp-block-quote {
    width: 105%;
  }
}

@media (min-width: 1600px) {
  .wp-block-quote {
    width: 110.24328%;
  }
}

ul.is-style-dropdown .dropdown-trigger {
  text-align: left;
  display: inline;
}

ul.is-style-dropdown > li > ul {
  list-style: none;
  margin-left: 0;
}

ul.is-style-dropdown > li:not(.is-active) > ul {
  display: none;
}

.c-main > [class*='wp-block']:not(.alignfull):not(.alignwide) {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

@media (min-width: 640px) {
  .c-main > [class*='wp-block']:not(.alignfull):not(.alignwide) {
    width: calc(100% - 110px);
  }
}

@media (min-width: 640px) {
  .wp-block-columns .wp-block-columns {
    display: flex;
    flex-wrap: nowrap;
  }
}

@media (min-width: 640px) and (max-width: 1199.98px) {
  .wp-block-columns .wp-block-columns .wp-block-column:not(:first-child) {
    margin-left: 30px;
  }
}

@media (max-width: 1199.98px) {
  .wp-block-column.wp-block-column {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .wp-block-columns {
    display: flex;
  }
  .wp-block-column:not(:first-child) {
    margin-left: 30px;
  }
  .wp-block-columns--main > .wp-block-column {
    margin: 0;
  }
  .wp-block-columns--main {
    justify-content: space-between;
  }
  .wp-block-column--thin {
    width: 29.05983%;
    flex-basis: auto;
  }
  .wp-block-column--wide {
    width: 63.24786%;
    flex-basis: auto;
  }
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BODY
   ========================================================================== */
body {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
  scroll-padding: calc(var(--admin-bar-height, 0) + var(--header-height, 0));
}

@supports (--custom: property) {
  body {
    min-height: calc(var(--full-height) - var(--admin-bar-height));
  }
}

@media (min-width: 640px) {
  body {
    padding-top: 65px;
  }
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
:root {
  --full-height: 100vh;
  --initial-height: 100vh;
  --scroll-width: 0;
  --admin-bar-height: 0px;
}

html {
  color: #0c1319;
  background-color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.28571;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}

html.has-admin-bar {
  --admin-bar-height: 32px;
}

@media (max-width: 1199.98px) {
  html {
    overflow-x: hidden;
  }
}

@media (min-width: 640px) {
  html {
    font-size: 16px;
    line-height: 1.375;
  }
}

/* ==========================================================================
   #IFRAME
   ========================================================================== */
iframe {
  border: none;
}

/* ==========================================================================
   #INPUT
   ========================================================================== */
button, input, textarea, select {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  outline: none;
  letter-spacing: inherit;
  text-transform: inherit;
}

input, textarea, select, button {
  line-height: normal;
}

button {
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: currentColor;
}

/* ==========================================================================
   #PARAGRAPHS
   ========================================================================== */
p:empty {
  display: none;
}

/* ==========================================================================
   #SVG
   ========================================================================== */
svg {
  fill: currentColor;
  transition: fill 0.5s ease, stroke 0.5s ease;
}

use, svg {
  transform-origin: center center;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-of-type:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6 {
  color: #1e262f;
  margin-top: 0;
}

h1, h2, h3, h4,
.o-h1, .o-h2, .o-h3, .o-h4 {
  font-weight: 600;
}

h5, h6,
.o-h5, .o-h6 {
  font-weight: 400;
}

h1,
.o-h1 {
  font-size: 34px;
  line-height: 1.14706;
}

@media (min-width: 640px) {
  h1,
  .o-h1 {
    font-size: 64px;
    line-height: 1.06667;
  }
}

h2,
.o-h2 {
  font-size: 34px;
  line-height: 1.14706;
}

@media (min-width: 640px) {
  h2,
  .o-h2 {
    font-size: 42px;
    line-height: 1.04762;
  }
}

h3,
.o-h3 {
  font-size: 28px;
  line-height: 1.07143;
}

@media (min-width: 640px) {
  h3,
  .o-h3 {
    font-size: 40px;
    line-height: 1.1;
  }
}

h4,
.o-h4 {
  font-size: 18px;
  line-height: 1.11111;
}

@media (min-width: 640px) {
  h4,
  .o-h4 {
    font-size: 28px;
    line-height: 1.14286;
  }
}

h5,
.o-h5 {
  font-size: 16px;
  line-height: 1.1875;
}

@media (min-width: 640px) {
  h5,
  .o-h5 {
    font-size: 20px;
    line-height: 1.15;
  }
}

h6,
.o-h6 {
  font-size: 14px;
}

@media (min-width: 640px) {
  h6,
  .o-h6 {
    font-size: 16px;
  }
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

/* stylelint-disable */
html.js .js-lazy-img {
  transition: opacity 0.3s ease;
}

html.js .js-lazy-img:not(.js-lazy-loaded) {
  opacity: 0;
}

/* stylelint-enable */
/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: currentColor;
  text-decoration: none;
  transition: color 0.3s ease;
}

html.no-touch a:hover, a:active {
  color: #ff5900;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #ANCHOR
   ========================================================================== */
.o-anchor {
  position: relative;
  height: 0;
  width: 0;
}

.o-anchor__link {
  position: absolute;
  top: -46px;
  left: 0;
  pointer-events: none;
  visibility: hidden;
}

@supports (--custom: property) {
  .o-anchor__link {
    top: calc(-46px - var(--admin-bar-height));
  }
}

@media (min-width: 640px) {
  .o-anchor__link {
    top: -65px;
  }
  @supports (--custom: property) {
    .o-anchor__link {
      top: calc(-65px - var(--admin-bar-height));
    }
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.o-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-family: inherit;
  color: inherit;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 0.57143rem 1rem;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  border-radius: 0;
  border: 1px solid transparent;
  font-size: 14px;
}

/* Style variants
   ========================================================================== */
.o-btn--primary {
  background-color: #ff5900;
  color: #fff;
}

html.no-touch .o-btn--primary:hover {
  background-color: #c44805;
  color: #fff;
}

.o-btn--primary:active {
  background-color: #8b3101;
  color: #fff;
}

.o-btn--secondary {
  background-color: #1e262f;
  border-color: #1e262f;
  color: #fff;
}

html.no-touch .o-btn--secondary:hover, .o-btn--secondary:active {
  border-color: currentColor;
  background-color: transparent;
  color: #1e262f;
}

.o-btn--outline {
  border-color: currentColor;
  background-color: transparent;
  color: #1e262f;
}

html.no-touch .o-btn--outline:hover, .o-btn--outline:active {
  background-color: #1e262f;
  border-color: #1e262f;
  color: #fff;
}

.o-btn--outline-white {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}

html.no-touch .o-btn--outline-white:hover, .o-btn--outline-white:active {
  background-color: #fff;
  border-color: #fff;
  color: #1e262f;
}

/* Size variants
   ========================================================================== */
.o-btn--cta {
  min-width: 144px;
}

/* ==========================================================================
   #EXPAND
   ========================================================================== */
.o-expand {
  position: relative;
}

.o-expand__hidden,
.o-expand:before {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
}

.o-expand[data-text]:before {
  content: attr(data-text);
}

.o-expand--600 .o-expand__hidden,
.o-expand--600:before {
  font-weight: 600;
}

.o-expand__target {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
}

/* ==========================================================================
   #FILTER HEADER
   ========================================================================== */
.o-filter-header.o-filter-header {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-left: -37px;
  margin-bottom: 20px;
  padding: 0;
}

.o-filter-header__item {
  margin-left: 35px;
  margin-bottom: 10px;
}

.o-filter-header__button {
  color: #202831;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-color 0.3s ease;
  padding-left: 2px;
  padding-right: 2px;
}

html.no-touch .o-filter-header__button:hover, .o-filter-header__button:active {
  color: #ff5900;
}

.o-filter-header__button.is-active {
  border-color: #ff5900;
}

@media (min-width: 640px) {
  .o-filter-header.o-filter-header {
    margin-left: -42px;
  }
  .o-filter-header__item {
    margin-left: 40px;
  }
  .o-filter-header__button {
    border-width: 5px;
  }
}

@media (min-width: 1024px) {
  .o-filter-header__button {
    border-width: 6px;
  }
}

/* ==========================================================================
   #GENERIC LINKS
   ========================================================================== */
.o-generic-link,
.o-generic-links a {
  color: #234067;
  text-decoration: underline;
}

html.no-touch .o-generic-link:hover, html.no-touch
.o-generic-links a:hover {
  color: #fa6400;
  text-decoration: underline;
}

.o-generic-link:active,
.o-generic-links a:active {
  color: #fa6400;
  text-decoration: underline;
}

/* ==========================================================================
   #HAMBURGER
   ========================================================================== */
.o-hamburger__wrapper {
  display: block;
  width: 18px;
  height: 16px;
  position: relative;
  line-height: 1;
  margin: auto;
}

.o-hamburger__bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  opacity: 1;
  left: 0;
  transform: none;
  transition: transform 0.25s ease, width 0.25s;
  transform-origin: center center;
  border-radius: 1px;
}

.o-hamburger__bar:nth-child(1) {
  top: 0;
}

.o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
  top: 7px;
  width: 13px;
  left: auto;
  right: 0;
}

.o-hamburger__bar:nth-child(4) {
  top: 14px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(1) {
  transform: scaleX(0) translateY(9px);
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(2) {
  transform: rotate(45deg) translate(2px, -2px);
  width: 21.6px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(3) {
  transform: rotate(-45deg) translate(2px, 2px);
  width: 21.6px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(4) {
  transform: scaleX(0) translateY(-9px);
}

@media (min-width: 640px) {
  .o-hamburger__wrapper {
    width: 27px;
    height: 23px;
  }
  .o-hamburger__bar {
    height: 3px;
  }
  .o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
    top: 10px;
    width: 19px;
  }
  .o-hamburger__bar:nth-child(4) {
    top: 20px;
  }
  .o-hamburger.is-active .o-hamburger__bar:nth-child(1) {
    transform: scaleX(0) translateY(11px);
  }
  .o-hamburger.is-active .o-hamburger__bar:nth-child(2) {
    transform: rotate(45deg) translate(4px, -4px);
    width: 32.4px;
  }
  .o-hamburger.is-active .o-hamburger__bar:nth-child(3) {
    transform: rotate(-45deg) translate(4px, 4px);
    width: 32.4px;
  }
  .o-hamburger.is-active .o-hamburger__bar:nth-child(4) {
    transform: scaleX(0) translateY(-11px);
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 640px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 640px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 640px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 640px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LINK
   ========================================================================== */
html.no-touch .o-links-orange a:hover, .o-links-orange a.is-active, .o-links-orange a:active, html.no-touch
.o-link-orange:hover,
.o-link-orange.is-active,
.o-link-orange:active {
  color: #ff5900;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

@media (min-width: 640px) {
  .o-wrapper {
    width: calc(100% - 110px);
  }
}

.o-wrapper-padding {
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 640px) {
  .o-wrapper-padding {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media (min-width: 1310px) {
  .o-wrapper-padding {
    padding-right: calc(50% - 600px);
    padding-left: calc(50% - 600px);
  }
}

.o-anti-wrapper {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 640px) {
  .o-anti-wrapper {
    margin-left: -55px;
    margin-right: -55px;
  }
}

@media (min-width: 1310px) {
  .o-anti-wrapper {
    width: 100vw;
    margin: 0;
  }
}

/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */
.c-breadcrumbs {
  font-size: 11px;
  margin-bottom: 1.69rem;
  display: flex;
  flex-wrap: wrap;
}

.c-breadcrumbs__item + .c-breadcrumbs__item:before {
  content: '|';
  display: inline-block;
  margin-left: 0.3em;
  margin-right: 0.3em;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.c-buttons {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 1.3rem;
}

.c-buttons__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-family: inherit;
  color: inherit;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 0.57143rem 1rem;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  border-radius: 0;
  border: 1px solid transparent;
  font-size: 14px;
  background-color: #ff5900;
  color: #fff;
  min-width: 150px;
}

html.no-touch .c-buttons__item:hover {
  background-color: #c44805;
  color: #fff;
}

.c-buttons__item:active {
  background-color: #8b3101;
  color: #fff;
}

.c-buttons.c-buttons > * {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 0;
}

.c-buttons__item.is-style-outline {
  border-color: currentColor;
  background-color: transparent;
  color: #1e262f;
}

html.no-touch .c-buttons__item.is-style-outline:hover, .c-buttons__item.is-style-outline:active {
  background-color: #1e262f;
  border-color: #1e262f;
  color: #fff;
}

.c-buttons__item.is-style-outline-white {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}

html.no-touch .c-buttons__item.is-style-outline-white:hover, .c-buttons__item.is-style-outline-white:active {
  background-color: #fff;
  border-color: #fff;
  color: #1e262f;
}

@media (min-width: 640px) {
  .c-buttons {
    margin-left: -13px;
    margin-top: -18px;
  }
  .c-buttons.c-buttons > * {
    margin-left: 13px;
    margin-top: 18px;
  }
}

@media (min-width: 1200px) {
  .c-buttons.is-style-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #COVER
   ========================================================================== */
.c-cover {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #1e262f;
}

.c-cover__title {
  color: inherit;
  margin-bottom: 15px;
}

.c-cover__text {
  margin-bottom: 30px;
  max-width: 694px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .c-cover {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .c-cover__text {
    margin-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  .c-cover {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .c-cover__text {
    margin-bottom: 20px;
  }
}

/* ==========================================================================
   #DESIGN REGISTRY
   ========================================================================== */
.c-design-registry__sort {
  position: relative;
  display: inline-block;
  width: auto;
  vertical-align: middle;
  appearance: none;
  letter-spacing: 0.042em;
  padding-right: 20px;
  background: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M2 2L7.5 8L13 2' stroke='%231e262f' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E") center right/10px 6px no-repeat;
}

.c-design-registry__sort:focus {
  outline: 0;
}

.c-design-registry__sort:focus::-ms-value {
  color: inherit;
  background-color: #fff;
}

.c-design-registry__sort::-ms-expand {
  display: none;
}

.c-design-registry__sort:-moz-focusring {
  color: transparent;
  text-shadow: none;
}

.c-design-registry__count {
  align-self: center;
  width: 100%;
}

.c-design-registry__search-input {
  font-size: 14px;
  border-bottom: 3px solid rgba(255, 89, 0, 0.3);
  transition: border-color 0.3s ease;
  padding: 10px 30px 10px 0;
  display: block;
  width: 100%;
}

.c-design-registry__search-input:focus {
  border-color: #ff5900;
}

.c-design-registry__search-input::placeholder {
  color: #5f5e5e;
}

.c-design-registry__search {
  width: 320px;
  max-width: 100%;
  position: relative;
  align-self: center;
  margin-left: auto;
}

.c-design-registry__submit {
  position: absolute;
  right: 0;
  height: 100%;
  width: 32px;
  top: 0;
  color: #707070;
}

html.no-touch .c-design-registry__submit:hover, .c-design-registry__submit:active {
  color: #1e262f;
}

.c-design-registry__header {
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.c-design-registry__filters {
  margin-bottom: 30px;
}

.c-design-registry__item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  padding: 8px 12px;
}

.c-design-registry__item-title {
  margin-bottom: 4px;
  line-height: 1;
}

.c-design-registry__item-authors {
  font-size: 14px;
  margin-bottom: 0;
}

.c-design-registry__item-filters {
  font-size: 14px;
}

.c-design-registry__item-links {
  margin-bottom: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 0;
}

.c-design-registry__item-links:before {
  display: block;
  height: 10px;
  content: '';
}

.c-design-registry__item-link {
  margin-bottom: 0;
}

.c-design-registry__item-accordion {
  display: flex;
  flex-direction: column;
}

.c-design-registry__item-toggle {
  margin-left: auto;
  margin-right: auto;
  text-indent: -200vw;
  overflow: hidden;
}

.c-design-registry__item-toggle > * {
  display: flex;
  width: 34px;
  height: 33px;
  overflow: hidden;
  position: relative;
}

.c-design-registry__item-toggle > *:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: currentColor transparent transparent transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  margin-left: -3px;
  transition: transform 0.3s ease;
}

.c-design-registry__item-accordion.is-active .c-design-registry__item-toggle > *:after {
  transform: rotate(180deg);
}

.c-design-registry__item-accordion.is-active .c-design-registry__item-links {
  max-height: 7.71429em;
}

.c-design-registry__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.c-design-registry__pagination > * {
  display: block;
  border: 1px solid #ddd;
  padding: 0.4em 0.7em;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-right: -1px;
}

html.no-touch .c-design-registry__pagination > *:hover, .c-design-registry__pagination > *:active {
  color: #fff;
  background-color: #234067;
}

.c-design-registry__pagination > *:last-child {
  margin-right: 0;
}

.c-design-registry__pagination > .current {
  color: #fff;
  background-color: #234067;
}

.c-design-registry__item-footer {
  color: #9b9b9b;
}

.c-design-registry__help {
  position: absolute;
  right: -20px;
  top: calc(100% - 20px);
  padding: 25px;
  width: calc(100% + 40px);
  max-width: 560px;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.c-design-registry__help-trigger {
  width: 40px;
  height: 40px;
  color: #707070;
}

.c-design-registry__help-trigger.is-active + .c-design-registry__help {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

@media (max-width: 639.98px) {
  .c-design-registry__count {
    margin-bottom: 20px;
  }
}

@media (min-width: 640px) {
  .c-design-registry__help {
    right: 0;
    width: 100%;
    top: calc(100% - 15px);
  }
  .c-design-registry__count {
    width: auto;
  }
  .c-design-registry__header {
    justify-content: space-between;
  }
}

/* ==========================================================================
   #FEATURED TILES
   ========================================================================== */
.c-featured-tiles {
  margin-top: 35px;
  padding-bottom: 35px;
  background: linear-gradient(to bottom, transparent 125px, #f5f6f6 125px, #f5f6f6) repeat-x top left;
}

.c-featured-tiles--no-bg.c-featured-tiles--no-bg {
  background-image: none;
}

.c-featured-tiles__items {
  margin-bottom: -20px;
}

.c-featured-tiles__item {
  border-top: 8px solid #ff5900;
  display: block;
}

.c-featured-tiles__items > * {
  margin-bottom: 20px;
  margin-left: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  background-color: #fff;
}

.c-featured-tiles__media {
  position: relative;
  max-height: 130px;
  overflow: hidden;
}

.c-featured-tiles__media:before {
  content: '';
  display: block;
  padding-top: 40.45802%;
}

.c-featured-tiles__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-featured-tiles__text {
  padding: 22px;
}

@media (max-width: 1023.98px) {
  .c-featured-tiles__item:nth-child(n + 3) {
    display: none;
  }
}

@media (min-width: 640px) {
  .c-featured-tiles {
    margin-top: 55px;
    padding-bottom: 55px;
    background: linear-gradient(to bottom, transparent 145px, #f5f6f6 145px, #f5f6f6) repeat-x top left;
  }
  .c-featured-tiles__item {
    border-top-width: 10px;
  }
  .c-featured-tiles__items > * {
    margin-left: 20px;
    width: calc(50% - 20px);
  }
  .c-featured-tiles__items {
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-featured-tiles__title {
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .c-featured-tiles__items {
    margin-left: -15px;
    margin-bottom: -15px;
  }
  .c-featured-tiles__items > * {
    margin-left: 15px;
    width: calc(100% / 3 - 15px);
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .c-featured-tiles__item {
    border-top-width: 12px;
  }
  .c-featured-tiles__title {
    margin-bottom: 45px;
  }
  .c-featured-tiles {
    margin-top: 90px;
    padding-bottom: 90px;
    background: linear-gradient(to bottom, transparent 170px, #f5f6f6 170px, #f5f6f6) repeat-x top left;
  }
  .c-featured-tiles__text {
    padding: 33px;
  }
  .c-featured-tiles__media {
    height: 160px;
    max-height: none;
  }
  .c-featured-tiles__media:before {
    display: none;
  }
}

/* ==========================================================================
   #FILTER
   ========================================================================== */
.c-filter {
  padding-top: 35px;
  padding-bottom: 45px;
}

.c-filter__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 33px;
}

.c-filter__filters {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
}

.c-filter__form {
  margin-top: 20px;
  width: 100%;
}

.c-filter__title {
  margin-bottom: 0;
  max-width: calc(100% - 60px);
  margin-right: 10px;
}

.c-filter__all {
  margin-bottom: 0.3em;
  border: none;
  background-color: transparent;
}

.c-filter__select {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  cursor: default;
}

.c-filter__select-value {
  border-bottom: 6px solid #ff5900;
  padding: 0.2em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.3s ease;
}

.c-filter__select.is-active .c-filter__select-value {
  border-color: #e02020;
}

.c-filter__select-chevron {
  flex-shrink: 0;
  flex-grow: 0;
  transition: fill 0.3s ease;
}

.c-filter__select.is-active .c-filter__select-chevron {
  fill: #ff5900;
}

.c-filter__select-options {
  background-color: #ff5900;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.247651);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  font-size: 16px;
  padding: 14px 15px;
  transition: opacity 0.225s ease, visibility 0.225s ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.c-filter__select.is-active .c-filter__select-options {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition-duration: 0.3s, 0.3s;
  pointer-events: auto;
}

.c-filter__select-option {
  margin-bottom: 1em;
}

.c-filter__select-option:last-child {
  margin-bottom: 0;
}

.c-filter__select-button {
  text-align: left;
  cursor: pointer;
}

.c-filter__select-button.is-active {
  font-weight: 700;
}

.c-filter__search {
  width: 100%;
  position: relative;
}

.c-filter__search-input {
  border-bottom: 3px solid rgba(255, 89, 0, 0.26);
  transition: border-color 0.3s ease;
  padding: 8px 20px 8px 0;
  width: 100%;
  display: block;
}

.c-filter__search-input::placeholder {
  color: #5f5e5e;
}

.c-filter__search-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  border: none;
  background-color: transparent;
}

.c-filter__search-input:focus {
  border-color: #ff5900;
}

.c-filter__search-icon {
  width: 12px;
  height: 12px;
  transition: fill 0.3s ease;
  fill: rgba(0, 0, 0, 0.4);
}

.c-filter__items {
  position: relative;
}

.c-filter__items:after {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  transition: visibility 0.225s ease, opacity 0.225s ease;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  top: -10px;
  left: -10px;
}

.c-filter.is-loading .c-filter__items:after {
  opacity: 1;
  visibility: visible;
  transition: 0.3s, 0.3s;
}

html.no-touch .c-filter__search-submit:hover .c-filter__search-icon, .c-filter__search-submit:active .c-filter__search-icon {
  fill: #000;
}

.c-filter__more {
  margin-top: 25px;
}

.c-filter.no-more .c-filter__more {
  display: none;
}

.c-filter__select-value {
  white-space: nowrap;
}

@media (min-width: 640px) {
  .c-filter {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .c-filter__title {
    margin-right: 20px;
  }
  .c-filter__form {
    margin-top: 30px;
  }
  .c-filter__filters {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin-left: -20px;
  }
  .c-filter__select {
    margin-left: 20px;
  }
  .c-filter__select--member-type {
    min-width: 17.61281%;
  }
  .c-filter__select--member-thematic-area {
    min-width: 22.27074%;
  }
  .c-filter__select--member-thematic-area {
    min-width: 22.27074%;
  }
  .c-filter__select--research-region {
    min-width: 24.59971%;
  }
  .c-filter__select--member-location {
    min-width: 24.89083%;
  }
  .c-filter__header {
    margin-bottom: 35px;
  }
  .c-filter__more {
    margin-top: 40px;
  }
  .c-filter__filters {
    margin-bottom: 15px;
  }
  .c-filter__search-submit {
    width: 32px;
  }
  .c-filter__search-icon {
    width: 14px;
    height: 13px;
  }
  .c-filter__search-input {
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .c-filter {
    padding-bottom: 80px;
  }
  .c-filter__filters {
    margin-left: -32px;
    min-width: 0;
  }
  .c-filter__select {
    width: 188px;
    margin-left: 32px;
    min-width: auto;
  }
  .c-filter__search {
    width: calc(100% - 880px);
    max-width: 322px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .c-filter__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .c-filter__select-value {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/* ==========================================================================
   #FOOTER NAV
   ========================================================================== */
.c-footer-nav {
  margin-bottom: 0;
  margin-left: 40px;
  margin-top: 1em;
}

.c-footer-nav__item,
.c-footer-nav__subitem {
  margin-bottom: 1em;
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
  .c-footer-nav {
    max-width: 180px;
  }
}

@media (min-width: 1200px) {
  .c-footer-nav {
    margin-left: 50px;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer__newsletter {
  background-color: #f5f6f6;
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-footer__newsletter-copy {
  text-align: center;
  max-width: 370px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__newsletter-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.c-footer__newsletter-column {
  position: relative;
  margin-bottom: 16px;
  display: flex;
}

.c-footer__newsletter-input {
  background-color: #fff;
  padding: 14px 10px 13px;
  display: block;
  width: 100%;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
}

.c-footer__newsletter-input::placeholder {
  color: #848a8f;
}

.c-footer__newsletter-submit {
  font-size: 14px;
  padding: 11px 30px 10px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__top {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fbfbfb;
}

.c-footer__logo {
  display: inline-block;
  margin-bottom: 24px;
}

.c-footer__menus {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-bottom: 40px;
  margin-top: -1em;
}

.c-footer__menus a {
  color: #234067;
}

html.no-touch .c-footer__menus a:hover, .c-footer__menus a:active {
  color: #fa6400;
}

.c-footer__bottom {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 13px;
  color: #9e9e9e;
}

.c-footer__bottom a {
  color: #1e262f;
}

.c-footer__social-links {
  margin-left: -30px;
  margin-top: -10px;
}

.c-footer__social-links-link {
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
}

.c-footer__social-links-link--twitter .c-footer__social-links-icon {
  width: 25px;
  margin-bottom: -2px;
}

.c-footer__social-links-link--youtube .c-footer__social-links-icon {
  width: 30px;
}

.c-footer__newsletter-message {
  margin-top: 1em;
}

.c-footer__newsletter-message:empty {
  display: none;
}

@media (min-width: 640px) {
  .c-footer__newsletter-column {
    margin-bottom: 5px;
  }
  .c-footer__newsletter-submit {
    margin-top: 30px;
    min-width: 142px;
  }
  .c-footer__menus {
    margin-left: 0;
    justify-content: space-between;
    width: calc(100% - 160px);
  }
  .c-footer__top-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .c-footer__social-links {
    display: flex;
    flex-wrap: wrap;
    width: 120px;
    margin-left: 0;
  }
  .c-footer__social-links-link {
    margin-bottom: 28px;
  }
}

@media (min-width: 1024px) {
  .c-footer__newsletter-inner {
    display: flex;
    align-items: center;
  }
  .c-footer__newsletter-copy {
    width: 26.33333%;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: left;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .c-footer__newsletter-inputs {
    width: 73.66667%;
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    position: relative;
  }
  .c-footer__newsletter-column {
    margin-bottom: 0;
    margin-right: 15px;
    width: 440px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .c-footer__newsletter-submit {
    margin: 0;
    min-width: 166px;
  }
  .c-footer__top-inner {
    display: flex;
    align-items: flex-start;
  }
  .c-footer__top-columns {
    width: 73.66667%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-left: auto;
  }
  .c-footer__logo {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .c-footer__menus {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-left: -40px;
  }
  .c-footer__social-links {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;
    min-width: 300px;
  }
  .c-footer__bottom {
    font-size: 14px;
  }
  .c-footer__newsletter-message {
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .c-footer__menus {
    margin-left: -50px;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background-color: #f5f6f6;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
}

@supports (--custom: property) {
  .c-header {
    top: var(--admin-bar-height);
  }
}

.c-header__inner {
  display: flex;
  align-items: center;
  height: 46px;
}

.c-header__hamburger {
  position: relative;
  z-index: 12;
  margin-left: auto;
}

.c-header__hamburger:not(.is-active):active {
  color: #ff5900;
}

.c-header__logo {
  display: block;
}

.c-header__logo-image {
  display: block;
  width: 60px;
  height: 22px;
}

@media (min-width: 640px) {
  .c-header__inner {
    height: 65px;
  }
  .c-header__logo-image {
    width: 92px;
    height: 33px;
  }
}

@media (max-width: 1199.98px) {
  .c-header__menu {
    background-color: #ff5900;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 11;
    padding: 95px 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 437px;
    transform: translateX(100%);
    transition: transform 0.225s ease;
  }
  .c-header__menu.is-active {
    transform: none;
    transition-duration: 0.3s;
  }
  .c-header__hamburger {
    padding: 10px;
    margin-right: -10px;
  }
}

@media (max-width: 1199.98px) and (min-width: 360px) {
  .c-header__menu.is-active {
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 1199.98px) and (min-width: 640px) {
  .c-header__menu {
    padding: 100px 74px;
  }
}

@media (min-width: 1200px) {
  .c-header {
    background-color: #fff;
  }
  .c-header__menu {
    display: flex;
    align-self: stretch;
    align-items: stretch;
    margin-left: auto;
  }
  .c-header__logo-image {
    width: 134px;
    height: 48px;
  }
}

.c-header__search {
  position: relative;
  height: 30px;
  align-self: center;
  padding: 5px 10px;
  margin-left: auto;
}

.c-header__search--mobile {
  display: none;
}

.c-header__search--desktop {
  display: block;
}

@media (max-width: 1199.98px) {
  .c-header__search {
    position: absolute;
    top: 20px;
    right: 110px;
    padding: 0;
    height: 25px;
    width: 25px;
  }
  .c-header__search--mobile {
    display: block;
  }
  .c-header__search--desktop {
    display: none;
  }
}

@media (max-width: 639.98px) {
  .c-header__search {
    top: 14px;
    right: 70px;
    padding: 0;
    height: 20px;
    width: 20px;
  }
}

.c-header__search-icon {
  width: 100%;
  height: 100%;
}

.c-header__search-icon svg {
  width: 100%;
  height: 100%;
}

.c-header__search-form {
  position: absolute;
  top: calc(100% + 17px);
  right: 0;
  display: none;
  background-color: #fff;
}

@media (max-width: 1199.98px) {
  .c-header__search-form {
    position: fixed;
    z-index: 10;
    top: 65px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.2);
  }
  .admin-bar .c-header__search-form {
    top: 97px;
  }
}

@media (max-width: 782px) {
  .admin-bar .c-header__search-form {
    top: 111px;
  }
}

@media (max-width: 639.98px) {
  .c-header__search-form {
    top: 46px;
  }
  .admin-bar .c-header__search-form {
    top: 92px;
  }
}

.c-header__search-form.is-open {
  display: block;
}

.c-header__search-form .searchform > div {
  display: flex;
}

@media (min-width: 1200px) {
  .c-header__search-form .searchform {
    padding: 0;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
}

.c-header__search-form .searchform input {
  border-bottom: 3px solid #ff5900;
  transition: all 0.5s ease;
  font-size: 14px;
  display: block;
}

.c-header__search-form .searchform input::placeholder {
  color: #5f5e5e;
}

.c-header__search-form .searchform input[type="text"] {
  padding: 8px 20px 8px 8px;
}

@media (max-width: 1199.98px) {
  .c-header__search-form .searchform input[type="text"] {
    width: 100%;
    padding: 12px 20px;
  }
}

@media (max-width: 639.98px) {
  .c-header__search-form .searchform input[type="text"] {
    padding: 10px 16px;
  }
}

.c-header__search-form .searchform input[type="submit"] {
  padding: 8px 8px;
  cursor: pointer;
}

.c-header__search-form .searchform input[type="submit"]:hover {
  background-color: #ff5900;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .c-header__search-form .searchform input[type="submit"] {
    padding: 12px;
  }
}

@media (max-width: 639.98px) {
  .c-header__search-form .searchform input[type="submit"] {
    padding: 10px;
  }
}

/* ==========================================================================
   #HERO
   ========================================================================== */
.c-hero {
  position: relative;
  background-color: #343c45;
  display: flex;
  align-items: stretch;
}

.c-hero:before {
  content: '';
  width: 0;
  height: 380px;
}

.c-hero__inner {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  max-width: 600px;
}

.c-hero__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-hero__title {
  margin-bottom: 15px;
  color: inherit;
}

.c-hero__title:last-child {
  margin-bottom: 0;
}

.c-hero__button {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: -17px;
  left: 0;
  padding: 0;
}

.c-hero__button-icon {
  width: 14px;
  height: 9px;
  display: block;
  margin: auto;
}

@media (min-width: 640px) {
  .c-hero:before {
    height: 420px;
  }
  .c-hero__button {
    width: 55px;
    height: 55px;
    bottom: -27px;
  }
  .c-hero__button-icon {
    width: 21px;
    height: 14px;
  }
}

/* ==========================================================================
   #ICON TILES
   ========================================================================== */
.c-icon-tiles {
  display: flex;
}

.c-icon-tiles__items {
  margin-bottom: -50px;
}

.c-icon-tiles__items > * {
  margin-bottom: 50px;
}

.c-icon-tiles__image {
  margin-bottom: 9px;
  display: block;
}

.c-icon-tiles__title {
  font-size: inherit;
  margin-bottom: 0;
}

.c-icon-tiles__text {
  margin-bottom: 10px;
}

.c-icon-tiles__button {
  min-width: 140px;
}

@media (min-width: 640px) {
  .c-icon-tiles__title {
    margin-bottom: 8px;
  }
  .c-icon-tiles__text {
    margin-bottom: 16px;
  }
  .c-icon-tiles__items {
    display: flex;
    margin-bottom: -45px;
    flex-wrap: wrap;
  }
  .c-icon-tiles__items > * {
    width: calc(50% - 35px);
    margin-right: 35px;
    margin-bottom: 45px;
  }
  .c-icon-tiles__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .c-icon-tiles__button {
    margin-top: auto;
  }
}

@media (min-width: 1024px) {
  .c-icon-tiles__image {
    margin-bottom: 15px;
  }
  .c-icon-tiles__items {
    margin-left: -24px;
  }
  .c-icon-tiles__items > * {
    width: calc(25% - 24px);
    margin-left: 24px;
    margin-right: 0;
  }
}

/* ==========================================================================
   #IMAGE SLIDES
   ========================================================================== */
.c-image-slides {
  margin-bottom: 1.3rem;
}

.c-image-slides__items {
  position: relative;
}

.c-image-slides__item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  transition: visibility 0.225s ease, opacity 0.225s ease;
  opacity: 0;
  visibility: hidden;
}

.c-image-slides__item.is-active {
  opacity: 1;
  visibility: visible;
  position: relative;
  z-index: 1;
  transition-duration: 0.3s, 0.3s;
}

.c-image-slides__image {
  display: block;
  margin: auto;
  width: 607px;
}

/* ==========================================================================
   #IMAGE STATS
   ========================================================================== */
.c-image-stats {
  background-color: #f5f6f6;
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-image-stats__title {
  margin-bottom: 30px;
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.c-image-stats__media {
  margin: 0;
}

.c-image-stats__image {
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.c-image-stats .c-stats {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .c-image-stats {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .c-image-stats__title {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .c-image-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .c-image-stats__media {
    width: 50%;
    max-width: 492px;
    padding-right: 20px;
  }
  .c-image-stats__stats {
    width: 50%;
    max-width: 444px;
    padding-left: 20px;
  }
  .c-image-stats__caption {
    text-align: center;
  }
  .c-image-stats__image {
    max-width: 100%;
  }
  .c-image-stats__title {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .c-image-stats {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

/* ==========================================================================
   #IMAGE TILES
   ========================================================================== */
.c-image-tiles {
  margin-bottom: 1.3rem;
}

.c-image-tiles__items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: -13px;
}

.c-image-tiles.is-style-vertical .c-image-tiles__items {
  margin-bottom: -20px;
}

.c-image-tiles__items.c-image-tiles__items > * {
  width: 586px;
  max-width: 100%;
  margin-bottom: 13px;
  margin-left: 0;
  margin-right: 0;
}

.c-image-tiles.is-style-vertical .c-image-tiles__items > * {
  margin-bottom: 20px;
  width: auto;
}

.c-image-tiles__item {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.248634);
  display: flex;
  line-height: 1.3;
}

.c-image-tiles:not(.is-style-vertical) a.c-image-tiles__item:hover .c-image-tiles__button-chevron {
  background-color: #c44805;
  color: #fff;
}

.c-image-tiles:not(.is-style-vertical) a.c-image-tiles__item:active .c-image-tiles__button-chevron {
  background-color: #8b3101;
  color: #fff;
}

.c-image-tiles.is-style-vertical .c-image-tiles__item {
  box-shadow: none;
  flex-direction: column;
}

.c-image-tiles__item:before {
  content: '';
  height: 120px;
  width: 0;
  display: block;
}

.c-image-tiles.is-style-vertical .c-image-tiles__item:before {
  display: none;
}

.c-image-tiles__media {
  width: 32.8125%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  margin: 0;
}

.c-image-tiles.is-style-vertical .c-image-tiles__media {
  width: 100%;
  margin-bottom: 1em;
}

.c-image-tiles.is-style-vertical .c-image-tiles__media:before {
  content: '';
  display: block;
  padding-top: 56.15385%;
}

.c-image-tiles__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-image-tiles__content {
  padding: 12px 10px 8px 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
}

.c-image-tiles.is-style-vertical .c-image-tiles__content {
  padding: 0;
}

.c-image-tiles__title {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 0;
  color: inherit;
}

.c-image-tiles.is-style-vertical .c-image-tiles__title {
  margin-bottom: 0.5em;
}

.c-image-tiles__button.c-image-tiles__button {
  margin-top: 1rem;
}

.c-image-tiles__button-chevron {
  align-self: flex-end;
  width: 17px;
  height: 17px;
  padding: 0;
  margin-top: auto;
}

.c-image-tiles__button-chevron-icon {
  transform: rotate(-90deg);
}

@media (max-width: 479.98px) {
  .c-image-tiles__item {
    font-size: 12px;
  }
  .c-image-tiles__button-chevron-icon {
    width: 6px;
    height: 4px;
  }
  .c-image-tiles__text {
    overflow: hidden;
    max-height: 3.9em;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

@media (min-width: 480px) {
  .c-image-tiles__items.c-image-tiles__items > * {
    margin-bottom: 21px;
  }
  .c-image-tiles__items {
    margin-bottom: -21px;
  }
  .c-image-tiles__button-chevron {
    width: 35px;
    height: 35px;
    margin-right: -8px;
    margin-bottom: -5px;
    margin-top: auto;
  }
  .c-image-tiles__media {
    width: 30.71672%;
  }
  .c-image-tiles__content {
    padding: 35px 24px 20px;
  }
  .c-image-tiles__title {
    margin-bottom: 10px;
  }
  .c-image-tiles__item:before {
    height: 204px;
  }
}

@media (min-width: 640px) {
  .c-image-tiles.is-style-vertical .c-image-tiles__items {
    margin-bottom: -30px;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__items > * {
    margin-bottom: 30px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__item {
    flex-direction: row;
    max-width: 820px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__media {
    width: 58.35866%;
    margin-bottom: 0;
    max-width: 384px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__content {
    margin-left: 18px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__title {
    margin-bottom: 1em;
  }
}

@media (min-width: 1024px) {
  .c-image-tiles__items {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .c-image-tiles__items {
    margin-bottom: -35px;
  }
  .c-image-tiles__items.c-image-tiles__items > * {
    max-width: none;
    width: calc(50% - 14px);
    margin-bottom: 35px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__items {
    margin-bottom: -35px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__items > * {
    margin-bottom: 35px;
  }
  .c-image-tiles.is-style-vertical .c-image-tiles__content {
    margin-left: 24px;
  }
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  color: #293950;
}

.c-main-nav__chevron {
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.c-main-nav__link,
.c-main-nav__sublink {
  color: inherit;
}

html.no-touch .c-main-nav__link:hover, .c-main-nav__link.is-active, .c-main-nav__link:active, html.no-touch
.c-main-nav__sublink:hover,
.c-main-nav__sublink.is-active,
.c-main-nav__sublink:active {
  color: inherit;
}

@media (max-width: 1199.98px) {
  .c-main-nav__link {
    font-size: 26px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  .c-main-nav__item {
    margin-bottom: 30px;
  }
  .c-main-nav__item:active, .c-main-nav__item.is-active {
    color: #fff;
  }
  .c-main-nav__item.current-page-ancestor, .c-main-nav__item.current-menu-item {
    color: #fff;
  }
  .c-main-nav__subitem.current-page-ancestor, .c-main-nav__subitem.current-menu-item {
    font-weight: 600;
  }
  .c-main-nav__dropdown {
    color: #fff;
    margin-top: 0;
    line-height: 1.2;
    overflow: hidden;
    max-height: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease, max-height 0.3s ease;
    visibility: hidden;
    opacity: 0;
    font-size: 20px;
    padding-left: 30px;
  }
  .c-main-nav__item.is-active > .c-main-nav__link {
    color: #fff;
  }
  .c-main-nav__item.is-active .c-main-nav__dropdown {
    opacity: 1;
    visibility: visible;
    max-height: 320px;
  }
  .c-main-nav__subitem {
    margin: 0.66667em 0 0 0;
  }
  .c-main-nav__subitem:first-child {
    margin-top: calc(0.66667em + 10px);
  }
  .c-main-nav__subitem.is-active {
    color: #fff;
  }
  .c-main-nav__chevron {
    box-sizing: content-box;
    padding: 15px 20px;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -18px;
  }
  .c-main-nav__chevron * {
    pointer-events: none;
  }
  .c-main-nav__item.is-active .c-main-nav__chevron {
    transform: rotate(180deg);
  }
}

@media (min-width: 640px) {
  .c-main-nav__chevron {
    width: 16px;
    height: 11px;
  }
}

@media (min-width: 1200px) {
  .c-main-nav {
    color: #0c1319;
    display: flex;
    align-items: stretch;
    margin-bottom: 0;
  }
  .c-main-nav__item {
    margin-left: 16px;
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: stretch;
  }
  .c-main-nav__item > .c-main-nav__link {
    padding: 0 4px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .c-main-nav__item > .c-main-nav__link:before {
    content: '';
    display: block;
    background-color: #ff5900;
    height: 8px;
    width: 100%;
    transition: transform 0.225s ease;
    transform: translateY(-100%);
    position: absolute;
    left: 0;
    top: 0;
  }
  html.no-touch .c-main-nav__item:hover > .c-main-nav__link, .c-main-nav__item.is-active > .c-main-nav__link, .c-main-nav__item:active > .c-main-nav__link {
    color: #ff5900;
    font-weight: 600;
  }
  html.no-touch .c-main-nav__item:hover > .c-main-nav__link:before, .c-main-nav__item.is-active > .c-main-nav__link:before, .c-main-nav__item:active > .c-main-nav__link:before {
    transform: none;
    transition-duration: 0.3s;
  }
  html.no-touch .c-main-nav__item:hover .c-main-nav__dropdown, .c-main-nav__item.is-active .c-main-nav__dropdown {
    opacity: 1;
    visibility: visible;
    transition-duration: 0.3s, 0.3s;
  }
  .c-main-nav__item.current-page-ancestor > .c-main-nav__link,
  .c-main-nav__item.current-menu-item > .c-main-nav__link {
    color: #ff5900;
    font-weight: 600;
  }
  .c-main-nav__item.current-page-ancestor > .c-main-nav__link:before,
  .c-main-nav__item.current-menu-item > .c-main-nav__link:before {
    transform: none;
    transition-duration: 0.3s;
  }
  .c-main-nav__chevron {
    width: 11px;
    height: 6px;
    margin-left: 3px;
  }
  .c-main-nav__dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #ff5900;
    width: 235px;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.225s ease, opacity 0.225s ease;
  }
  .c-main-nav__dropdown {
    margin-top: 0;
    padding: 24px 15px 24px 20px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  .c-main-nav__subitem {
    margin-bottom: 1em;
  }
  html.no-touch .c-main-nav__subitem:hover > .c-main-nav__sublink, .c-main-nav__subitem.is-active > .c-main-nav__sublink, .c-main-nav__subitem:active > .c-main-nav__sublink {
    font-weight: 600;
  }
  .c-main-nav__subitem.current-page-ancestor > .c-main-nav__sublink,
  .c-main-nav__subitem.current-menu-item > .c-main-nav__sublink {
    font-weight: 600;
  }
  .c-main-nav__subitem:last-child {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   #MEMBER
   ========================================================================== */
.c-member {
  padding: 40px 16px 25px;
  position: static;
  transition: visibility 0.225s ease, opacity 0.225s;
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.2668);
}

.c-member.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.c-member__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.c-member__image {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin-right: 6px;
  flex-grow: 0;
  flex-shrink: 0;
}

.c-member__header-text {
  width: calc(100% - 94px);
  flex-grow: 1;
  flex-shrink: 1;
}

.c-member__header-buttons {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 24px;
}

.c-member__header-title {
  margin-bottom: 0;
}

.c-member__header-button {
  display: flex;
  width: 100%;
  margin-top: 13px;
  max-width: 320px;
}

.c-member__content {
  margin-bottom: 20px;
}

.c-member__meta {
  display: flex;
  margin-left: -30px;
  margin-bottom: 30px;
}

.c-member__meta-item {
  width: calc(50% - 30px);
  margin-left: 30px;
}

.c-member__publications-title {
  margin-bottom: 1em;
}

.c-member__publications {
  padding-bottom: 25px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

.c-member__members-title {
  margin-bottom: 1em;
  width: 100%;
}

.c-member__members-items {
  display: flex;
}

.c-member__view-all {
  margin-top: 30px;
}

.c-member__member-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin: auto auto 10px;
  display: block;
}

.c-member__member {
  margin-right: 25px;
  flex-shrink: 0;
}

.c-member__publications-more {
  margin-top: 15px;
}

@media (max-width: 479.98px) {
  .c-member__member:nth-child(n + 4) {
    display: none;
  }
}

@media (max-width: 639.98px) {
  .c-member__publications.is-active .c-member__publications-more,
  .c-member__publications:not(.is-active) .c-research-tile:nth-child(n + 3) {
    display: none;
  }
}

@media (min-width: 640px) {
  .c-member {
    padding: 45px 35px 30px;
  }
  .c-member__publications-more {
    margin-top: 22px;
  }
  .c-member__image {
    width: 112px;
    height: 112px;
    margin-right: 16px;
  }
  .c-member__header {
    flex-wrap: nowrap;
    margin-bottom: 30px;
  }
  .c-member__header-text {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 15px;
  }
  .c-member__header-buttons {
    flex-shrink: 1;
    flex-grow: 0;
    width: 144px;
    margin: 0;
  }
  .c-member__header-button {
    margin-top: 7px;
  }
  .c-member__header-button:first-child {
    margin-top: 0;
  }
  .c-member__content {
    margin-bottom: 25px;
  }
  .c-member__meta {
    margin-left: -90px;
  }
  .c-member__meta-item {
    margin-left: 90px;
    width: auto;
  }
  .c-member__members {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .c-member__members-title {
    width: 100%;
  }
  .c-member__view-all {
    margin-left: auto;
    margin-top: 15px;
  }
  .c-member__member:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .c-member {
    padding: 77px 77px 70px;
  }
  .c-member__image {
    width: 142px;
    height: 142px;
    margin-right: 26px;
  }
  .c-member__header {
    margin-bottom: 60px;
  }
  .c-member__meta {
    margin-left: -110px;
  }
  .c-member__meta-item {
    margin-left: 110px;
    width: auto;
  }
  .c-member__meta-item-title {
    margin-bottom: 7px;
  }
  .c-member__publications-title {
    margin-bottom: 20px;
  }
  .c-member__publications {
    padding-bottom: 40px;
    margin-bottom: 25px;
  }
  .c-member__member {
    margin-right: 45px;
  }
}

/* ==========================================================================
   #MEMBERS
   ========================================================================== */
.c-members__items {
  margin-bottom: -15px;
}

.c-members__item {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2668);
  display: flex;
  align-items: flex-start;
  padding: 20px 10px 20px 17px;
  margin-bottom: 15px;
  cursor: pointer;
}

.c-members__item-thumbnail {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  filter: grayscale(100%);
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-members__item-title {
  font-size: 23px;
  margin-bottom: 2px;
  line-height: 1;
}

.c-members__item-website {
  margin-top: 8px;
  display: inline-block;
}

@media (max-width: 639.98px) {
  .c-members__item:not(.is-active):nth-child(n + 7) {
    display: none;
  }
}

@media (max-width: 1023.98px) {
  .c-members__item:not(.is-active):nth-child(n + 13) {
    display: none;
  }
}

@media (min-width: 640px) {
  .c-members__item {
    margin-bottom: 17px;
    margin-left: 15px;
    width: calc(50% - 15px);
    padding: 25px 15px 25px 20px;
  }
  .c-members__item-thumbnail {
    margin-right: 9px;
    width: 74px;
    height: 74px;
  }
  .c-members__items {
    margin-bottom: -17px;
    margin-left: -15px;
    flex-wrap: wrap;
    display: flex;
  }
  .c-members__item-title {
    margin-bottom: 4px;
  }
}

@media (min-width: 1024px) {
  .c-members__item {
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(100% / 3 - 20px);
  }
  .c-members__items {
    margin-bottom: -20px;
    margin-left: -20px;
  }
  .c-members__item:not(.is-active):nth-child(n + 25) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .c-members__item {
    padding: 30px 20px 30px 25px;
  }
  .c-members__item-thumbnail {
    margin-right: 11px;
    width: 90px;
    height: 90px;
  }
}

/* ==========================================================================
   #OVERLAY
   ========================================================================== */
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.c-overlay {
  height: calc(100vh - 46px);
  position: fixed;
  left: 0;
  top: 46px;
  width: 100%;
  background-color: rgba(99, 115, 129, 0.86);
  z-index: 3;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding: 30px 0;
  transition: opacity 0.375s, visibility 0.375s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@supports (--custom: property) {
  .c-overlay {
    height: calc(var(--full-height) - 46px - var(--admin-bar-height));
  }
}

@media (min-width: 640px) {
  .c-overlay {
    height: calc(100vh - 65px);
  }
  @supports (--custom: property) {
    .c-overlay {
      height: calc(var(--full-height) - 65px - var(--admin-bar-height));
    }
  }
}

@supports (--custom: property) {
  .c-overlay {
    top: calc(46px + var(--admin-bar-height));
  }
}

.c-overlay.is-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition-duration: 0.5s, 0.5s;
}

.c-overlay__inner {
  margin: auto;
  width: calc(100% - 26px);
  max-width: 996px;
  position: relative;
}

.c-overlay__close {
  position: absolute;
  left: 50%;
  top: -15px;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  margin-left: -15px;
  z-index: 2;
  border-radius: 50%;
  padding: 0;
}

.c-overlay.is-loading .c-overlay__close {
  opacity: 0;
  visibility: hidden;
}

.c-overlay.is-loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -24px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  background: transparent;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@media (min-width: 640px) {
  .c-overlay {
    top: 65px;
    padding: 40px 0;
  }
  @supports (--custom: property) {
    .c-overlay {
      top: calc(65px + var(--admin-bar-height));
    }
  }
  .c-overlay__inner {
    width: calc(100% - 80px);
  }
  .c-overlay__close {
    width: 36px;
    height: 36px;
    margin-left: -18px;
    top: -18px;
  }
  .c-overlay__close-icon {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 1024px) {
  .c-overlay__close {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    top: -25px;
  }
  .c-overlay__close-icon {
    width: 16px;
    height: 16px;
  }
  .c-overlay {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* ==========================================================================
   #PAGE DATE
   ========================================================================== */
.c-page-date {
  font-style: normal;
  margin-bottom: 1.3rem;
  display: block;
}

/* ==========================================================================
   #PAGE TITLE
   ========================================================================== */
.c-page-title {
  margin-top: -0.1em;
  word-break: normal;
}

.c-pagination {
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
}

.c-pagination__page--prev svg {
  transform: rotate(90deg);
}

.c-pagination__page--next svg {
  transform: rotate(-90deg);
}

.c-pagination__pages {
  display: flex;
  gap: 8px;
}

.c-pagination__pages .c-pagination__page {
  padding: 2px 5px;
  background-color: rgba(172, 169, 167, 0.3);
  color: #000;
  transition: all 0.375s ease;
}

.c-pagination__pages .c-pagination__page:hover, .c-pagination__pages .c-pagination__page--current {
  background-color: #ff5900;
  color: #fff;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2.6rem;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 0 1.3rem 1.3rem 0;
}

.aligncenter {
  margin: 0 auto 1.3rem;
}

.alignright {
  float: right;
  margin: 0 0 1.3rem 1.3rem;
}

.alignleft {
  float: left;
  margin: 0 1.3rem 1.3rem 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.65rem;
}

/* ==========================================================================
   #RELATED PROJECTS
   ========================================================================== */
.c-related-projects {
  margin-bottom: 1.3rem;
}

.c-related-projects.no-items {
  display: none;
}

.c-related-projects__items {
  margin-bottom: -15px;
}

.c-related-projects__item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  padding: 16px 19px;
  display: flex;
  flex-direction: column;
}

.c-related-projects__items > * {
  margin-bottom: 15px;
}

.c-related-projects__item-countries {
  margin-bottom: 0;
}

.c-related-projects__item-title {
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  line-height: 1.375;
  max-height: 2.75em;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-related-projects__item-members {
  display: flex;
  margin-bottom: -15px;
  margin-top: auto;
  justify-self: flex-end;
}

.c-related-projects__item-member {
  width: 30px;
  height: 30px;
  margin-left: -6px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  cursor: default;
}

html.no-touch .c-related-projects__item-member:hover, .c-related-projects__item-member:active {
  z-index: 1;
}

.c-related-projects__item-member:first-child {
  margin-left: 0;
}

@media (min-width: 640px) {
  .c-related-projects__items {
    margin-bottom: -18px;
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-related-projects__item {
    padding: 23px 24px;
  }
  .c-related-projects__items > * {
    margin-left: 20px;
    margin-bottom: 18px;
    width: calc(50% - 20px);
  }
  .c-related-projects__item-countries {
    margin-bottom: 2px;
  }
  .c-related-projects__item-member {
    width: 38px;
    height: 38px;
    margin-left: -7.6px;
  }
}

@media (min-width: 1024px) {
  .c-related-projects__items {
    margin-bottom: -20px;
    margin-left: -23px;
  }
  .c-related-projects__items > * {
    margin-left: 23px;
    margin-bottom: 20px;
    width: calc(50% - 23px);
  }
  .c-related-projects__item {
    padding: 24px 26px;
  }
  .c-related-projects__item-member {
    width: 40px;
    height: 40px;
    margin-left: -8px;
  }
  .c-related-projects__item-countries {
    margin-bottom: 4px;
  }
  .c-related-projects__item-title {
    margin-bottom: 15px;
  }
}

/* ==========================================================================
   #RELATED RESOURCES
   ========================================================================== */
.c-related-resources {
  margin-bottom: 1.3rem;
}

.c-related-resources .c-research-tile.is-hidden {
  display: none;
}

.c-related-resources__view-all.c-related-resources__view-all {
  margin-top: 15px;
}

@media (min-width: 640px) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 27px;
  }
}

@media (min-width: 1024px) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 45px;
  }
}

@media (min-width: 1200px) {
  .c-related-resources__view-all.c-related-resources__view-all {
    margin-top: 50px;
  }
}

/* ==========================================================================
   #RESEARCH TILE
   ========================================================================== */
.c-research-tile {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 15px 12px 18px;
  position: relative;
  margin-bottom: 15px;
}

.c-research-tile__bar {
  border: none;
  border-top: 5px solid currentColor;
  width: 100%;
  margin: 0;
  color: #aca9a7;
  position: absolute;
  left: 0;
  top: 0;
}

.c-research-tile__type {
  margin-bottom: 0;
}

.c-research-tile__title {
  margin-bottom: 10px;
}

.c-research-tile__footer {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
}

.c-research-tile__footer-item:after {
  content: '•';
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.c-research-tile__footer-item:last-child:after {
  display: none;
}

.c-research-tile__title {
  max-height: 2.375em;
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (min-width: 640px) {
  .c-research-tile {
    padding: 20px 16px 12px 21px;
  }
  .c-research-tile__bar {
    height: 6px;
  }
  .c-research-tile__title:last-child {
    min-height: 2.375em;
  }
}

@media (min-width: 1024px) {
  .c-research-tile {
    padding: 20px 22px 14px 26px;
  }
}

/* ==========================================================================
   #RESEARCH TILES
   ========================================================================== */
.c-research-tiles {
  margin-bottom: -15px;
}

.c-research-tiles > * {
  margin-left: 0;
}

@media (min-width: 640px) {
  .c-research-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -22px;
  }
  .c-research-tiles > * {
    width: calc(50% - 10px);
    margin-bottom: 22px;
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .c-research-tiles > * {
    width: calc(50% - 14px);
    margin-bottom: 22px;
  }
}

/* ==========================================================================
   #RESOURCE TILES
   ========================================================================== */
.c-resource-tiles {
  margin-bottom: 1.3rem;
}

/* ==========================================================================
   #RESOURCES
   ========================================================================== */
.c-resources {
  margin-bottom: 1.3rem;
}

.c-resources__item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  display: block;
  margin-bottom: 15px;
}

.c-resources__item-bar {
  color: #0c1319;
  border: none;
  border-top: 4px solid currentColor;
  margin: 0;
  width: 100%;
}

.c-resource__item-media {
  position: relative;
  padding-top: 39.76834%;
}

.c-resources__item-thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-resources__item-content {
  padding: 10px 7px;
}

.c-resources__item-title {
  margin-bottom: 7px;
  color: #212b36;
}

.c-resources__items {
  margin-bottom: -15px;
}

@media (max-width: 639.98px) {
  .c-resources__item-title {
    font-size: 18px;
  }
  .c-resources__item:not(.is-active):nth-child(n + 4) {
    display: none;
  }
}

@media (max-width: 1023.98px) {
  .c-resources__item:not(.is-active):nth-child(n + 7) {
    display: none;
  }
}

@media (min-width: 640px) {
  .c-resources__item-bar {
    border-width: 5px;
  }
  .c-resources__items {
    margin-left: -18px;
    margin-bottom: -18px;
    display: flex;
    flex-wrap: wrap;
  }
  .c-resources__item {
    margin-bottom: 22px;
    margin-left: 18px;
    width: calc(50% - 18px);
  }
  .c-resources .c-filter__header {
    margin-bottom: 45px;
  }
  .c-resources__item-content {
    padding: 16px 26px;
  }
}

@media (min-width: 1024px) {
  .c-resources__item-bar {
    border-width: 6px;
  }
  .c-resources__items {
    margin-left: -21px;
    margin-bottom: -31px;
  }
  .c-resources__item {
    margin-bottom: 31px;
    margin-left: 21px;
    width: calc(100% / 3 - 21px);
  }
  .c-resources__item:not(.is-active):nth-child(n + 10) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .c-resources .c-filter__header {
    margin-bottom: 80px;
  }
}

.c-search-item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  display: block;
  padding: 16px;
  margin-bottom: 16px;
}

/* ==========================================================================
   #SECTION
   ========================================================================== */
.c-section {
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (min-width: 640px) {
  .c-section {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}

@media (min-width: 1200px) {
  .c-section {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.c-section__columns > * {
  flex-basis: auto;
}

.c-section__column:last-child > *:last-child {
  margin-bottom: 0;
}

@media (max-width: 639.98px) {
  .c-section__columns--reverse {
    flex-direction: column-reverse;
    display: flex;
  }
  .c-section__columns:not(.c-section__columns--reverse):last-child .wp-block-column:last-child > :last-child {
    margin-bottom: 0;
  }
  .c-section__columns.c-section__columns--reverse:last-child .wp-block-column:first-child > :last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 640px) {
  .c-section__columns:last-child .wp-block-column:last-child > :last-child {
    margin-bottom: 0;
  }
  .c-section__columns.is-resource-row {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .c-section__columns {
    display: flex;
    justify-content: space-between;
  }
  .c-section__columns--reverse {
    flex-direction: row-reverse;
  }
  .c-section__columns:not(.is-header-row) > *:first-child,
  .c-section__columns.is-header-row > *:last-child {
    width: 30.33333%;
    flex-basis: auto;
  }
  .c-section__columns:not(.is-header-row) > *:last-child,
  .c-section__columns.is-header-row > *:first-child {
    width: 66.33333%;
    flex-basis: auto;
  }
  .c-section__columns.c-section__columns > * {
    margin: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

/* ==========================================================================
   #STATS
   ========================================================================== */
.c-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 232px;
  margin-bottom: 1.3rem;
}

.c-stats__stat {
  font-size: 48px;
  font-weight: 300;
  line-height: 0.9;
}

.c-stats__item {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.c-stats > * {
  width: 116px;
}

.c-stats__description.c-stats__description {
  display: inline-block;
  text-decoration: none;
  color: currentColor;
  padding-bottom: 0.4em;
}

.c-stats__description {
  font-size: 10px;
}

@media (min-width: 480px) {
  .c-stats__stat {
    font-size: 68px;
  }
  .c-stats {
    width: 400px;
  }
  .c-stats > * {
    width: 200px;
  }
  .c-stats__description {
    font-size: 16px;
  }
}

@media (max-width: 639.98px) {
  .c-stats > *:nth-child(n + 3) {
    border-top: 1px solid #c4cdd5;
  }
  .c-stats > *:nth-child(even) {
    border-left: 1px solid #c4cdd5;
  }
}

@media (min-width: 640px) {
  .c-stats > * {
    flex-shrink: 0;
    flex-grow: 0;
    width: 25%;
    border-left: 1px solid #c4cdd5;
  }
  .c-stats > *:nth-child(4n - 3) {
    border-left: none;
  }
  .c-stats > *:nth-child(n + 5) {
    border-top: 1px solid #c4cdd5;
  }
  .c-stats {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .c-stats--matrix {
    width: 424px;
  }
  .c-stats--matrix .c-stats__item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .c-stats--matrix.c-stats > * {
    border-left: 0;
    border-top: 0;
    width: 212px;
  }
  .c-stats--matrix.c-stats > *:nth-child(n + 3) {
    border-top: 1px solid #c4cdd5;
  }
  .c-stats--matrix.c-stats > *:nth-child(even) {
    border-left: 1px solid #c4cdd5;
  }
}

/* ==========================================================================
   #SUBMENU
   ========================================================================== */
.c-submenu {
  margin-bottom: 1.3rem;
  border-bottom: 1px solid #c4cdd5;
  word-break: normal;
}

.c-submenu.is-style-vertical {
  border: none;
}

.c-submenu__items {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  margin-bottom: -1px;
  max-width: 100%;
}

.c-submenu__items > * {
  margin-left: 0;
  margin-right: 0;
}

.c-submenu:not(.is-style-vertical) .c-submenu__items > * {
  min-width: 120px;
  flex-basis: calc(100% / 5);
  flex-shrink: 1;
  flex-grow: 1;
}

.c-submenu--many:not(.is-style-vertical) .c-submenu__items > * {
  min-width: 0;
  flex-basis: calc(100% / 6);
  padding-left: 10px;
  padding-right: 10px;
}

.c-submenu.is-style-vertical .c-submenu__items {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: visible;
  margin-bottom: 0;
}

.c-submenu__item {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 15px 20px;
  color: #0c1319;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-color 0.3s ease;
}

html.no-touch .c-submenu__item:hover, .c-submenu__item:active {
  border-color: #ff5900;
}

.c-submenu.is-style-vertical .c-submenu__item {
  padding: 0;
  border: none;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
}

.c-submenu.is-style-vertical .c-submenu__items > * {
  margin-bottom: 0.71429em;
}

.c-submenu.is-style-vertical .c-submenu__items > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .c-submenu__item {
    padding: 15px 20px;
    border-width: 3px;
  }
}

@media (min-width: 1024px) {
  .c-submenu__items {
    overflow: visible;
  }
}

@media (min-width: 1200px) {
  .c-submenu__item {
    padding: 18px 30px;
  }
  .c-submenu--many:not(.is-style-vertical) .c-submenu__items > * {
    padding-left: 20px;
    padding-right: 20px;
    flex-basis: auto;
  }
}

@media (min-width: 1024px) {
  .c-submenu__item {
    white-space: nowrap;
  }
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-c-white {
  color: #fff;
}

.u-c-navy-blue {
  color: #293950;
}

.u-c-blue-darker {
  color: #234067;
}

.u-c-blue-darkest {
  color: #0c1319;
}

.u-c-orange {
  color: #ff5900;
}

.u-c-text {
  color: #0c1319;
}

.u-c-heading {
  color: #1e262f;
}

.u-c-gray-dark {
  color: #5f5e5e;
}

.u-c-navy-blue-darker {
  color: #1e262f;
}

/* ==========================================================================
   #DISPLAY
   ========================================================================== */
@media (max-width: 639.98px) {
  .u-hide-small {
    display: none;
  }
}

@media (min-width: 640px) {
  .u-hide-medium {
    display: none;
  }
}

@media (min-width: 1200px) {
  .u-hide-xlarge {
    display: none;
  }
}

@media (min-width: 1400px) {
  .u-hide-full {
    display: none;
  }
}

@media (max-width: 639.98px) {
  .u-hide-on-small, .has-hide-on-small {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1199.98px) {
  .u-hide-on-medium, .has-hide-on-medium {
    display: none;
  }
}

@media (min-width: 1200px) {
  .u-hide-on-xlarge, .has-hide-on-xlarge {
    display: none;
  }
}

@media (max-width: 639.98px) {
  .u-show-medium {
    display: none;
  }
}

@media (max-width: 1023.98px) {
  .u-show-large {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-show-xlarge {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .u-show-full {
    display: none;
  }
}

@media (max-width: 639.98px) {
  .u-show-small {
    display: none;
  }
}

/* ==========================================================================
   #FIT
   ========================================================================== */
.u-fit-cover {
  object-fit: cover;
}

.u-fit-contain {
  object-fit: contain;
}

.u-fit-scale-down {
  object-fit: scale-down;
}

/* ==========================================================================
   #FLIP
   ========================================================================== */
.u-flip-horizontal {
  transform: scaleX(-1);
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

/* ==========================================================================
   #HEIGHT
   ========================================================================== */
.u-h-100vw {
  height: calc(100vh - 46px);
}

@supports (--custom: property) {
  .u-h-100vw {
    height: calc(var(--full-height) - 46px - var(--admin-bar-height));
  }
}

@media (min-width: 640px) {
  .u-h-100vw {
    height: calc(100vh - 65px);
  }
  @supports (--custom: property) {
    .u-h-100vw {
      height: calc(var(--full-height) - 65px - var(--admin-bar-height));
    }
  }
}

.u-h-mh100vw {
  min-height: calc(100vh - 46px);
}

@supports (--custom: property) {
  .u-h-mh100vw {
    min-height: calc(var(--full-height) - 46px - var(--admin-bar-height));
  }
}

@media (min-width: 640px) {
  .u-h-mh100vw {
    min-height: calc(100vh - 65px);
  }
  @supports (--custom: property) {
    .u-h-mh100vw {
      min-height: calc(var(--full-height) - 65px - var(--admin-bar-height));
    }
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 640px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #MARGIN
   ========================================================================== */
.u-no-last-margin > *:last-child {
  margin-bottom: 0;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-small {
  margin-bottom: 20px;
}

.u-mb-medium {
  margin-bottom: 30px;
}

.u-mb-large {
  margin-bottom: 40px;
}

@media (min-width: 640px) {
  .u-mb-small {
    margin-bottom: 30px;
  }
  .u-mb-medium {
    margin-bottom: 40px;
  }
  .u-mb-large {
    margin-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  .u-mb-small {
    margin-bottom: 40px;
  }
  .u-mb-medium {
    margin-bottom: 50px;
  }
  .u-mb-large {
    margin-bottom: 60px;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-no-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.u-no-scroll-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.u-no-overscroll-y {
  overscroll-behavior-y: none;
  -ms-scroll-chaining: none;
}

.u-no-overscroll-x {
  overscroll-behavior-x: none;
  -ms-scroll-chaining: none;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-underlined {
  text-decoration: underline;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-nowrap {
  white-space: nowrap;
}

.u-fsz-14 {
  font-size: 14px;
}

.u-fsz-bigger {
  font-size: 14px;
}

@media (min-width: 640px) {
  .u-fsz-bigger {
    font-size: 18px;
  }
}

@media (min-width: 640px) {
  .u-fsz-bigger {
    font-size: 20px;
  }
}

.u-fsz-small {
  font-size: 14px;
}

@media (min-width: 640px) {
  .u-fsz-small {
    font-size: 16px;
  }
}

.u-fsz-smaller {
  font-size: 12px;
}

@media (min-width: 640px) {
  .u-fsz-smaller {
    font-size: 15px;
  }
}

.u-fsz-smallest {
  font-size: 11px;
}

@media (min-width: 640px) {
  .u-fsz-smallest {
    font-size: 14px;
  }
}

.u-fsz-12-14 {
  font-size: 12px;
}

@media (min-width: 1200px) {
  .u-fsz-12-14 {
    font-size: 14px;
  }
}

.u-fsz-12-16 {
  font-size: 12px;
}

@media (min-width: 640px) {
  .u-fsz-12-16 {
    font-size: 16px;
  }
}

.u-fsz-11-16 {
  font-size: 11px;
}

@media (min-width: 640px) {
  .u-fsz-11-16 {
    font-size: 16px;
  }
}
