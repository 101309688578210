///* ========================================================================
//   #LINK
//   ======================================================================== */

@mixin link-active-hover-click() {
  @at-root html.no-touch &:hover,
  &.is-active,
  &:active {
    @content;
  }
}

@mixin link-active-hover() {
  @at-root html.no-touch &:hover,
  &:active {
    @content;
  }
}

@mixin link-hover() {
  @at-root html.no-touch &:hover {
    @content;
  }
}

@mixin link-active() {
  &:active {
    @content;
  }
}

@mixin link-click-hover($all_devices: false) {
  @if($all_devices) {
    &:hover,
    &.is-active {
      @content;
    }
  }
  @else {
    @at-root html.no-touch &:hover,
    &.is-active {
      @content;
    }
  }
}

@mixin link-active-click() {
  &:active,
  &.is-active {
    @content;
  }
}

@mixin link-click() {
  &.is-active {
    @content;
  }
}


@mixin link-generic() {
  & {
    color: $color-blue-darker;
    text-decoration: underline;

    @include link-hover {
      color: $color-link-hover;
      text-decoration: underline;
    }

    @include link-active {
      color: $color-link-hover;
      text-decoration: underline;
    }
  }
}

