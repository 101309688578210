/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-buttons {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: $block-bottom-margin;
}

.c-buttons__item {
  @include button;
  @include button-primary;
  min-width: 150px;
}

.c-buttons.c-buttons > * {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 0;
}

.c-buttons__item.is-style-outline {
  @include button-outline;
}

.c-buttons__item.is-style-outline-white {
  @include button-outline-white;
}

@include bp(medium) {
  .c-buttons {
    margin-left: -13px;
    margin-top: -18px;
  }

  .c-buttons.c-buttons > * {
    margin-left: 13px;
    margin-top: 18px;
  }
}

@include bp(xlarge) {
  .c-buttons.is-style-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
