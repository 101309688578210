/* ==========================================================================
   #IMAGE TILES
   ========================================================================== */

.c-image-tiles {
  margin-bottom: $block-bottom-margin;
}

.c-image-tiles__items {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: -13px;
}

.c-image-tiles.is-style-vertical .c-image-tiles__items {
  margin-bottom: -20px;
}

.c-image-tiles__items.c-image-tiles__items > * {
  width: 586px;
  max-width: 100%;
  margin-bottom: 13px;
  margin-left: 0;
  margin-right: 0;
}

.c-image-tiles.is-style-vertical .c-image-tiles__items > * {
  margin-bottom: 20px;
  width: auto;
}

.c-image-tiles__item {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.248634);
  display: flex;
  line-height: 1.3;
}

.c-image-tiles:not(.is-style-vertical) a.c-image-tiles__item:hover .c-image-tiles__button-chevron {
  background-color: $color-orange-darker;
  color: $color-white;
}

.c-image-tiles:not(.is-style-vertical) a.c-image-tiles__item:active .c-image-tiles__button-chevron {
  background-color: $color-orange-darkest;
  color: $color-white;
}

.c-image-tiles.is-style-vertical .c-image-tiles__item {
  box-shadow: none;
  flex-direction: column;
}

.c-image-tiles__item:before {
  content: '';
  height: 120px;
  width: 0;
  display: block;
}

.c-image-tiles.is-style-vertical .c-image-tiles__item:before {
  display: none;
}

.c-image-tiles__media {
  width: (84/256) * 100%;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  margin: 0;
}

.c-image-tiles.is-style-vertical .c-image-tiles__media {
  width: 100%;
  margin-bottom: 1em;
}

.c-image-tiles.is-style-vertical .c-image-tiles__media:before {
  content: '';
  display: block;
  padding-top: (146/260) * 100%;
}

.c-image-tiles__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-image-tiles__content {
  padding: 12px 10px 8px 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
}

.c-image-tiles.is-style-vertical .c-image-tiles__content {
  padding: 0;
}

.c-image-tiles__title {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 0;
  color: inherit;
}

.c-image-tiles.is-style-vertical .c-image-tiles__title {
  margin-bottom: 0.5em;
}

.c-image-tiles__button.c-image-tiles__button {
  margin-top: 1rem;
}

.c-image-tiles__button-chevron {
  align-self: flex-end;
  width: 17px;
  height: 17px;
  padding: 0;
  margin-top: auto;
}

.c-image-tiles__button-chevron-icon {
  transform: rotate(-90deg);
}

@include bp-down(small) {
  .c-image-tiles__item {
    font-size: 12px;
  }

  .c-image-tiles__button-chevron-icon {
    width: 6px;
    height: 4px;
  }

  .c-image-tiles__text {
    overflow: hidden;
    max-height: 3 * 1.3em;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

@include bp(small) {
  .c-image-tiles__items.c-image-tiles__items > * {
    margin-bottom: 21px;
  }

  .c-image-tiles__items {
    margin-bottom: -21px;
  }

  .c-image-tiles__button-chevron {
    width: 35px;
    height: 35px;
    margin-right: -8px;
    margin-bottom: -5px;
    margin-top: auto;
  }

  .c-image-tiles__media {
    width: (180/586) * 100%;
  }

  .c-image-tiles__content {
    padding: 35px 24px 20px;
  }

  .c-image-tiles__title {
    margin-bottom: 10px;
  }

  .c-image-tiles__item:before {
    height: 204px;
  }
}

@include bp(medium) {
  .c-image-tiles.is-style-vertical .c-image-tiles__items {
    margin-bottom: -30px;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__items > * {
    margin-bottom: 30px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__item {
    flex-direction: row;
    max-width: 820px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__media {
    width: 384/658 * 100%;
    margin-bottom: 0;
    max-width: 384px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__content {
    margin-left: 18px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__title {
    margin-bottom: 1em;
  }
}

@include bp(large) {
  .c-image-tiles__items {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .c-image-tiles__items {
    margin-bottom: -35px;
  }

  .c-image-tiles__items.c-image-tiles__items > * {
    max-width: none;
    width: calc(50% - 14px);
    margin-bottom: 35px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__items {
    margin-bottom: -35px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__items > * {
    margin-bottom: 35px;
  }

  .c-image-tiles.is-style-vertical .c-image-tiles__content {
    margin-left: 24px;
  }
}

