///* ========================================================================
//   #WORDPRESS
//   ======================================================================== */

@import "dropdown-style";

.c-main > [class*='wp-block']:not(.alignfull):not(.alignwide) {
  @include wrapper;
}

.has-hide-on-small {
  @extend .u-hide-on-small;
}

.has-hide-on-medium {
  @extend .u-hide-on-medium;
}

.has-hide-on-xlarge {
  @extend .u-hide-on-xlarge;
}

@include bp(medium) {
  .wp-block-columns .wp-block-columns {
    display: flex;
    flex-wrap: nowrap;
  }
}

@include bp-between(medium, xlarge) {
  .wp-block-columns .wp-block-columns .wp-block-column:not(:first-child) {
    margin-left: 30px;
  }
}

@include bp-down(xlarge) {
  .wp-block-column.wp-block-column {
    margin: 0;
  }
}

@include bp(xlarge) {
  .wp-block-columns {
    display: flex;
  }

  .wp-block-column:not(:first-child) {
    margin-left: 30px;
  }

  .wp-block-columns--main > .wp-block-column {
    margin: 0;
  }

  .wp-block-columns--main {
    justify-content: space-between;
  }

  .wp-block-column--thin {
    width: 340/1170 * 100%;
    flex-basis: auto;
  }

  .wp-block-column--wide {
    width: 740/1170 * 100%;
    flex-basis: auto;
  }
}
