/* ==========================================================================
   #BUTTONS
   ========================================================================== */

@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-family: inherit;
  color: inherit;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: (8/14) + rem 1rem;
  transition: color $transition-fast-in ease, background-color $transition-fast-in ease, border-color $transition-fast-in ease;
  border-radius: 0;
  border: 1px solid transparent;
  font-size: 14px;
}

@mixin button-primary {
  background-color: $color-orange;
  color: $color-white;

  @include link-hover {
    background-color: $color-orange-darker;
    color: $color-white;
  }

  @include link-active {
    background-color: $color-orange-darkest;
    color: $color-white;
  }
}

@mixin button-secondary {
  background-color: $color-headings;
  border-color: $color-headings;
  color: $color-white;

  @include link-active-hover {
    border-color: currentColor;
    background-color: transparent;
    color: $color-headings;
  }
}

@mixin button-outline {
  border-color: currentColor;
  background-color: transparent;
  color: $color-headings;

  @include link-active-hover {
    background-color: $color-headings;
    border-color: $color-headings;
    color: $color-white;
  }
}

@mixin button-outline-white {
  border-color: $color-white;
  background-color: transparent;
  color: $color-white;

  @include link-active-hover {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-headings;
  }
}
