/* ==========================================================================
   #CSS VAR
   ========================================================================== */

/**
 * Attach our clearfix mixin to a utility class.
 */

@mixin css-var {
  @supports (--custom:property) {
    @content;
  }
}
