/* ==========================================================================
   #MEMBERS
   ========================================================================== */

.c-members__items {
  margin-bottom: -15px;
}

.c-members__item {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2668);
  display: flex;
  align-items: flex-start;
  padding: 20px 10px 20px 17px;
  margin-bottom: 15px;
  cursor: pointer;
}

.c-members__item-thumbnail {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: block;
  filter: grayscale(100%);
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-members__item-title {
  font-size: 23px;
  margin-bottom: 2px;
  line-height: 1;
}

.c-members__item-website {
  margin-top: 8px;
  display: inline-block;
}

@include bp-down(medium) {
  .c-members__item:not(.is-active):nth-child(n + 7) {
    display: none;
  }
}

@include bp-down(large) {
  .c-members__item:not(.is-active):nth-child(n + 13) {
    display: none;
  }
}

@include bp(medium) {
  .c-members__item {
    margin-bottom: 17px;
    margin-left: 15px;
    width: calc(50% - 15px);
    padding: 25px 15px 25px 20px;
  }

  .c-members__item-thumbnail {
    margin-right: 9px;
    width: 74px;
    height: 74px;
  }

  .c-members__items {
    margin-bottom: -17px;
    margin-left: -15px;
    flex-wrap: wrap;
    display: flex;
  }

  .c-members__item-title {
    margin-bottom: 4px;
  }
}

@include bp(large) {
  .c-members__item {
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(100% / 3 - 20px);
  }

  .c-members__items {
    margin-bottom: -20px;
    margin-left: -20px;
  }

  .c-members__item:not(.is-active):nth-child(n + 25) {
    display: none;
  }
}

@include bp(xlarge) {
  .c-members__item {
    padding: 30px 20px 30px 25px;
  }

  .c-members__item-thumbnail {
    margin-right: 11px;
    width: 90px;
    height: 90px;
  }
}
