/* ==========================================================================
   #OVERLAY
   ========================================================================== */

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.c-overlay {
  @include height-100vh;
  position: fixed;
  left: 0;
  top: $header-height-small;
  width: 100%;
  background-color: rgba(#637381, 0.86);
  z-index: 3;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding: 30px 0;
  transition: opacity $transition-slow-out, visibility $transition-slow-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @supports (--custom:property) {
    top: calc(#{$header-height-small} + var(--admin-bar-height));
  }
}

.c-overlay.is-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition-duration: $transition-slow-in, $transition-slow-in;
}

.c-overlay__inner {
  margin: auto;
  width: calc(100% - 26px);
  max-width: 996px;
  position: relative;
}

.c-overlay__close {
  position: absolute;
  left: 50%;
  top: -15px;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  margin-left: -15px;
  z-index: 2;
  border-radius: 50%;
  padding: 0;
  //transition: opacity $transition-fast-in ease, color $transition-fast-in ease, background-color $transition-fast-in ease, visibility $transition-fast-in ease;
}

.c-overlay.is-loading .c-overlay__close {
  opacity: 0;
  visibility: hidden;
}

.c-overlay.is-loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -24px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  background: transparent;
  border-top: 4px solid $color-white;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

@include bp(medium) {
  .c-overlay {
    top: $header-height-medium;
    padding: 40px 0;

    @supports (--custom:property) {
      top: calc(#{$header-height-medium} + var(--admin-bar-height));
    }
  }

  .c-overlay__inner {
    width: calc(100% - 80px);
  }

  .c-overlay__close {
    width: 36px;
    height: 36px;
    margin-left: -18px;
    top: -18px;
  }

  .c-overlay__close-icon {
    width: 12px;
    height: 12px;
  }
}

@include bp(large) {
  .c-overlay__close {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    top: -25px;
  }

  .c-overlay__close-icon {
    width: 16px;
    height: 16px;
  }

  .c-overlay {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

