/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include wrapper;
}

.o-wrapper-padding {
  @include wrapper-padding;
}

.o-anti-wrapper {
  @include anti-wrapper;
}
