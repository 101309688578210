/* ==========================================================================
   #RELATED PROJECTS
   ========================================================================== */

.c-related-projects {
  margin-bottom: $block-bottom-margin;
}

.c-related-projects.no-items {
  display: none;
}

.c-related-projects__items {
  margin-bottom: -15px;
}

.c-related-projects__item {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.0675809);
  padding: 16px 19px;
  display: flex;
  flex-direction: column;
}

.c-related-projects__items > * {
  margin-bottom: 15px;
}

.c-related-projects__item-countries {
  margin-bottom: 0;
}

.c-related-projects__item-title {
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  line-height: (22/16);
  max-height: (22/16) * 2em;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-related-projects__item-members {
  display: flex;
  margin-bottom: -15px;
  margin-top: auto;
  justify-self: flex-end;
}

.c-related-projects__item-member {
  width: 30px;
  height: 30px;
  margin-left: -6px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  cursor: default;

  @include link-active-hover {
    z-index: 1;
  }
}

.c-related-projects__item-member:first-child {
  margin-left: 0;
}

@include bp(medium) {
  .c-related-projects__items {
    margin-bottom: -18px;
    margin-left: -20px;
    display: flex;
    flex-wrap: wrap;
  }

  .c-related-projects__item {
    padding: 23px 24px;
  }

  .c-related-projects__items > * {
    margin-left: 20px;
    margin-bottom: 18px;
    width: calc(50% - 20px);
  }

  .c-related-projects__item-countries {
    margin-bottom: 2px;
  }

  .c-related-projects__item-member {
    width: 38px;
    height: 38px;
    margin-left: -(6/30) * 38px;
  }
}

@include bp(large) {
  .c-related-projects__items {
    margin-bottom: -20px;
    margin-left: -23px;
  }

  .c-related-projects__items > * {
    margin-left: 23px;
    margin-bottom: 20px;
    width: calc(50% - 23px);
  }

  .c-related-projects__item {
    padding: 24px 26px;
  }

  .c-related-projects__item-member {
    width: 40px;
    height: 40px;
    margin-left: -(6/30) * 40px;
  }

  .c-related-projects__item-countries {
    margin-bottom: 4px;
  }

  .c-related-projects__item-title {
    margin-bottom: 15px;
  }
}

