/* ==========================================================================
   #FILTER HEADER
   ========================================================================== */

.o-filter-header.o-filter-header {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-left: -37px;
  margin-bottom: 20px;
  padding: 0;
}

.o-filter-header__item {
  margin-left: 35px;
  margin-bottom: 10px;
}

.o-filter-header__button {
  color: #202831;
  border-bottom: 2px solid transparent;
  transition: color $transition-fast-in ease, border-color $transition-fast-in ease;
  padding-left: 2px;
  padding-right: 2px;

  @include link-active-hover {
    color: $color-orange;
  }
}

.o-filter-header__button.is-active {
  border-color: $color-orange;
}

@include bp(medium) {
  .o-filter-header.o-filter-header {
    margin-left: -42px;
  }

  .o-filter-header__item {
    margin-left: 40px;
  }

  .o-filter-header__button {
    border-width: 5px;
  }
}

@include bp(large) {
  .o-filter-header__button {
    border-width: 6px;
  }
}
