/* ==========================================================================
   #ANCHOR
   ========================================================================== */

.o-anchor {
  position: relative;
  height: 0;
  width: 0;
}

.o-anchor__link {
  position: absolute;
  top: -$header-height-small;
  left: 0;
  pointer-events: none;
  visibility: hidden;

  @supports (--custom:property) {
    top: calc(-#{$header-height-small} - var(--admin-bar-height));
  }
}

@include bp(medium) {
  .o-anchor__link {
    top: -$header-height-medium;

    @supports (--custom:property) {
      top: calc(-#{$header-height-medium} - var(--admin-bar-height));
    }
  }
}
