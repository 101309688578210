///* ========================================================================
//   #LINK
//   ======================================================================== */

@mixin h1 {
  font-size: 34px;
  line-height: (39/34);

  @include bp(medium) {
    & {
      font-size: 64px;
      line-height: (64/60);
    }
  }
}

@mixin h2 {
  font-size: 34px;
  line-height: (39/34);

  @include bp(medium) {
    & {
      font-size: 42px;
      line-height: (44/42);
    }
  }
}

@mixin h3 {
  font-size: 28px;
  line-height: (30/28);

  @include bp(medium) {
    & {
      font-size: 40px;
      line-height: (44/40);
    }
  }
}

@mixin h4 {
  font-size: 18px;
  line-height: (20/18);

  @include bp(medium) {
    & {
      font-size: 28px;
      line-height: (32/28);
    }
  }
}

@mixin h5 {
  font-size: 16px;
  line-height: (19/16);

  @include bp(medium) {
    & {
      font-size: 20px;
      line-height: (23/20);
    }
  }
}

@mixin h6 {
  font-size: 14px;

  @include bp(medium) {
    & {
      font-size: 16px;
    }
  }
}

@mixin smallest {
  font-size: 11px;

  @include bp(medium) {
    & {
      font-size: 14px;
    }
  }
}

@mixin smaller {
  font-size: 12px;

  @include bp(medium) {
    & {
      font-size: 15px;
    }
  }
}

@mixin small {
  font-size: 14px;

  @include bp(medium) {
    & {
      font-size: 16px;
    }
  }
}

@mixin bigger {
  font-size: 14px;

  @include bp(medium) {
    & {
      font-size: 18px;
    }
  }

  @include bp(medium) {
    & {
      font-size: 20px;
    }
  }
}
