/* ==========================================================================
   #WORDPRESS
   ========================================================================== */

#wpadminbar {
  position: fixed;
  top: 0;
  left: 0;
}

@include bp-down(600px) {
  #wpadminbar #wp-admin-bar-wpseo-menu {
    display: none;
  }
}
