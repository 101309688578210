/* ==========================================================================
   #FOOTER NAV
   ========================================================================== */

.c-footer-nav {
  margin-bottom: 0;
  margin-left: 40px;
  margin-top: 1em;
}

.c-footer-nav__item,
.c-footer-nav__subitem {
  margin-bottom: 1em;
}

@include bp-between(large, xlarge) {
  .c-footer-nav {
    max-width: 180px;
  }
}

@include bp(xlarge) {
  .c-footer-nav {
    margin-left: 50px;
  }
}
