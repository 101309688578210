/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  color: $color-navy-blue;
}

.c-main-nav__chevron {
  vertical-align: middle;
  transition: transform $transition-fast-in ease;
}

.c-main-nav__link,
.c-main-nav__sublink {
  color: inherit;

  @include link-active-hover-click {
    color: inherit;
  }
}

@include bp-down(xlarge) {
  .c-main-nav__link {
    font-size: 26px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  .c-main-nav__item {
    margin-bottom: 30px;

    @include link-active-click {
      color: $color-white;
    }

    &.current-page-ancestor,
    &.current-menu-item {
      color: $color-white;
    }
  }

  .c-main-nav__subitem {
    &.current-page-ancestor,
    &.current-menu-item {
      font-weight: 600;
    }
  }

  .c-main-nav__dropdown {
    color: $color-white;
    margin-top: 0;
    line-height: 1.2;
    overflow: hidden;
    max-height: 0;
    transition: visibility $transition-fast-in ease, opacity $transition-fast-in ease, max-height $transition-fast-in ease;
    visibility: hidden;
    opacity: 0;
    font-size: 20px;
    padding-left: 30px;
  }

  .c-main-nav__item.is-active > .c-main-nav__link {
    color: $color-white;
  }

  .c-main-nav__item.is-active .c-main-nav__dropdown {
    opacity: 1;
    visibility: visible;
    max-height: 320px;
  }

  .c-main-nav__subitem {
    margin: (2/3) + em 0 0 0;
  }

  .c-main-nav__subitem:first-child {
    margin-top: calc(#{(2/3)}em + 10px);
  }

  .c-main-nav__subitem.is-active {
    color: $color-white;
  }

  .c-main-nav__chevron {
    box-sizing: content-box;
    padding: 15px 20px;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -18px;
  }

  .c-main-nav__chevron * {
    pointer-events: none;
  }

  .c-main-nav__item.is-active .c-main-nav__chevron {
    transform: rotate(180deg);
  }
}

@include bp(medium) {
  .c-main-nav__chevron {
    width: 16px;
    height: 11px;
  }
}

@include bp(xlarge) {
  .c-main-nav {
    color: #0c1319;
    display: flex;
    align-items: stretch;
    margin-bottom: 0;
  }

  .c-main-nav__item {
    margin-left: 16px;
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: stretch;
  }

  .c-main-nav__item > .c-main-nav__link {
    padding: 0 4px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      background-color: $color-orange;
      height: 8px;
      width: 100%;
      transition: transform $transition-fast-out ease;
      transform: translateY(-100%);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .c-main-nav__item {
    @include link-active-hover-click {
      & > .c-main-nav__link {
        color: $color-orange;
        font-weight: 600;

        &:before {
          transform: none;
          transition-duration: $transition-fast-in;
        }
      }
    }

    @include link-click-hover() {
      .c-main-nav__dropdown {
        opacity: 1;
        visibility: visible;
        transition-duration: $transition-fast-in, $transition-fast-in;
      }
    }

    &.current-page-ancestor > .c-main-nav__link,
    &.current-menu-item > .c-main-nav__link {
      color: $color-orange;
      font-weight: 600;

      &:before {
        transform: none;
        transition-duration: $transition-fast-in;
      }
    }
  }

  .c-main-nav__chevron {
    width: 11px;
    height: 6px;
    margin-left: 3px;
  }

  .c-main-nav__dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: $color-orange;
    width: 235px;
    color: $color-white;
    opacity: 0;
    visibility: hidden;
    transition: visibility $transition-fast-out ease, opacity $transition-fast-out ease;
  }

  .c-main-nav__dropdown {
    margin-top: 0;
    padding: 24px 15px 24px 20px;
    transition: opacity $transition-fast-in ease, visibility $transition-fast-in ease;
  }

  .c-main-nav__subitem {
    margin-bottom: 1em;

    @include link-active-hover-click {
      & > .c-main-nav__sublink {
        font-weight: 600;
      }
    }

    &.current-page-ancestor > .c-main-nav__sublink,
    &.current-menu-item > .c-main-nav__sublink {
      font-weight: 600;
    }
  }

  .c-main-nav__subitem:last-child {
    margin-bottom: 0;
  }
}
